export enum Group {
  JAVA = "JAVA",
  TEST = "TEST",
  ADMIN = "ADMIN",
}

export enum Role {
  REGULAR_USER = "REGULAR_USER",
  ADMINISTRATOR = "ADMINISTRATOR",
}

export interface Email {
  emailAddress: string;
}

export interface PhoneNumber {
  number: string;
}

interface Address {
  street?: string;
  zipCode?: string;
  city?: string;
}

interface BirthDate {
  birthDate?: string;
}

export interface Person {
  uuid: string;
  firstName: string;
  lastName: string;
  email: Email;
  phoneNumber?: PhoneNumber;
  imageLink?: string;
  inActive?: boolean;
  group: Group;
  role: Role;
  address: Address;
  birthDate: BirthDate;
  allowedToEdit?: boolean;
  profileImageId?: string;
}

export type IncompletePersonData = Partial<Person>;

export function initialsOf(person: Person | undefined): string {
  return `${person?.firstName?.substring(0, 1)} ${person?.lastName?.substring(0, 1) ?? ""}`;
}
