import { Card } from "react-bootstrap";
import { Media, MediaType } from "../../../common/backend/model/Media.model";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import "./MediaCard.scss";
import { NestedIconButton } from "../../../common/components/buttons/NestedIconButton";

export interface MediaCardProps {
  media: Media;
  onOpenMedia: (media: Media) => void;
  onOpenEditor: (media: Media) => void;
}

export const MediaCard: React.FC<MediaCardProps> = (props) => {
  const { media, onOpenMedia, onOpenEditor } = props;

  function formattedMetadata() {
    return (
      `${media.metaData?.description ? `Beskrivning: ${media.metaData?.description}` : ""}` +
      `${media.metaData?.description && (media.metaData?.photographer || media.metaData?.takenOn) ? "\n" : ""}` +
      `${media.metaData?.photographer ? `Fotograf: ${media.metaData?.photographer}` : ""}` +
      `${media.metaData?.photographer && media.metaData?.takenOn ? "\n" : ""}` +
      `${
        media.metaData?.takenOn
          ? `Tidpunkt: ${media.metaData?.takenOn
              .replace(/(?<=(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})).*/i, "")
              .replace("T", " ")}`
          : ""
      }`
    );
  }

  return (
    <Card className="square-card shadow-lg p-0 h-100 click-target" title={formattedMetadata()}>
      <div className="square-card-img-wrapper">
        {media.mediaType === MediaType.IMAGE ? (
          <Card.Img className="square-card-img" src={media.url} onClick={() => onOpenMedia(media)} />
        ) : (
          <video
            className="card-img-top square-card-video"
            src={media.url}
            onClick={() => onOpenMedia(media)}
            autoPlay={false}
            controls={false}
          />
        )}
      </div>

      <NestedIconButton
        icon={faGear}
        onClick={() => onOpenEditor(media)}
        title={"Bildinformation"}
        shadow
        positionAbsolute
        appearOnlyOnCardHover
      />
    </Card>
  );
};
