import { StackLayout } from "../../common/components/layout/StackLayout";
import { NewestLinks } from "../links/NewestLinks";
import { ActivePollsForLoggedInPerson } from "../polls/ActivePollsForLoggedInPerson";
import { FloatingActionButton } from "../../common/components/buttons/FloatingActionButton";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App.state";
import { useBackendApi } from "../../common/backend/backendApi";
import { SelectRespondantsModal } from "../../common/components/modal/SelectRespondantsModal";
import { Respondant } from "../../common/backend/model/Poll.model";
import { LatestScheduledEvents } from "../calendar/LatestScheduledEvents";
import { ImageOfTheDay } from "../gallery/ImageOfTheDay";

export const LandingPage: React.FC = () => {
  const appState = useContext(AppContext).state;
  const backendApi = useBackendApi();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    backendApi.persons.loadPersons();
  }, [backendApi.persons]);

  function openMail(value: Array<Respondant>) {
    window.location.href = `mailto:${value.map((respondant) => respondant.email.emailAddress).join(",")}`;
    setShowModal(false);
  }

  if (appState.persons.loading || !appState.persons.value) {
    return null;
  }

  return (
    <>
      <main className="container p-2">
        <StackLayout gap={2}>
          <ActivePollsForLoggedInPerson />
          <NewestLinks />
          <LatestScheduledEvents />
          <ImageOfTheDay />
        </StackLayout>

        <FloatingActionButton label="Skicka e-post" bottom={70} onClick={() => setShowModal(true)} />

        <SelectRespondantsModal
          show={showModal}
          selectedRespondants={[]}
          selectablePersons={appState.persons.value}
          onClose={() => setShowModal(false)}
          onSave={(value) => openMail(value)}
        />
      </main>
    </>
  );
};
