import { Group, IncompletePersonData, Person, Role } from "../../common/backend/model/Person.model";
import { StackLayout } from "../../common/components/layout/StackLayout";
import { LabelledTextInput } from "../../common/components/labelled-inputs/LabelledTextInput";
import React, { useContext, useState } from "react";
import { ActionButton } from "../../common/components/buttons/ActionButton";
import { LabelledDateInput } from "../../common/components/labelled-inputs/LabelledDateInput";
import { ConfirmRemoveModal } from "../../common/components/modal/ConfirmRemoveModal";
import { AppContext } from "../../App.state";
import { ValidationErrors, validationErrorsEmpty } from "../../common/backend/ValidationErrors";
import { LabelledSelect } from "../../common/components/labelled-inputs/LabelledSelect";
import { ProfileImageEditor } from "./ProfileImageEditor";

export interface PersonEditorProps {
  person?: Person;
  onClose?: () => void;
  onCreatePerson?: (person: Person, file: File) => Promise<void>; // Updated this line
  onUpdatePerson?: (updatedPerson: Person, file: File) => void;
  onRemovePerson?: (personUUID: string) => void;
  onValidatePerson?: (person: IncompletePersonData) => Promise<ValidationErrors>;
}

export const PersonEditor: React.FC<PersonEditorProps> = (props: PersonEditorProps) => {
  const {
    person,
    onClose = () => undefined,
    onCreatePerson = () => undefined,
    onUpdatePerson = () => undefined,
    onRemovePerson = () => undefined,
    onValidatePerson = () => Promise.resolve(validationErrorsEmpty()),
  } = props;

  const [editMode] = useState(!!person);
  const appState = useContext(AppContext).state;
  const [editedPerson, setEditedPerson] = useState<Partial<Person>>(person || {});
  const loggedInPerson = appState.loggedInPerson.value;
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(validationErrorsEmpty());

  const loggedInPersonIsAdmin = loggedInPerson?.role === Role.ADMINISTRATOR;
  const groupOptions = Object.values(Group).map((group) => ({ id: group, label: group }));
  const roleOptions = Object.values(Role).map((role) => ({ id: role, label: role }));

  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleImageSelect = (image: File) => {
    console.log("handleImageSelect: ", image);
    setSelectedImage(image);
  };

  function updateEditedPerson(fields: Partial<Person>) {
    setEditedPerson((prevValue) => {
      return {
        ...prevValue,
        ...fields,
      };
    });
  }

  function isAllowedToEdit() {
    return loggedInPersonIsAdmin || editedPerson?.allowedToEdit;
  }

  return (
    <div className="p-2">
      <StackLayout gap={4}>
        {editedPerson ? (
          <>
            <div>
              <ProfileImageEditor person={editedPerson} onImageSelect={handleImageSelect} />
              <LabelledTextInput
                focus={true}
                value={editedPerson?.firstName}
                label="Förnamn"
                className="mb-2"
                errors={validationErrors.for("firstName")}
                onChange={(firstName) => updateEditedPerson({ firstName: firstName })}
              />
              <LabelledTextInput
                focus={true}
                value={editedPerson?.lastName}
                label="Efternamn"
                className="mb-2"
                errors={validationErrors.for("lastName")}
                onChange={(lastName) => updateEditedPerson({ lastName: lastName })}
              />
              <LabelledTextInput
                focus={true}
                value={editedPerson?.email?.emailAddress}
                label="E-post"
                className="mb-2"
                errors={validationErrors.for("email.emailAddress")}
                onChange={(email) => updateEditedPerson({ email: { emailAddress: email } })}
              />
              <LabelledTextInput
                focus={true}
                value={editedPerson?.phoneNumber?.number}
                label="Mobil"
                className="mb-2"
                errors={validationErrors.for("phoneNumber.number")}
                onChange={(phoneNumber) => updateEditedPerson({ phoneNumber: { number: phoneNumber } })}
              />
              <LabelledTextInput
                focus={true}
                value={editedPerson?.address?.street ?? ""}
                label="Gata"
                className="mb-2"
                errors={validationErrors.for("address.street")}
                onChange={(street) => updateEditedPerson({ address: { ...editedPerson.address, street } })}
              />
              <LabelledTextInput
                focus={true}
                value={editedPerson?.address?.zipCode ?? ""}
                label="Postnummer"
                className="mb-2"
                errors={validationErrors.for("address.zipCode")}
                onChange={(zipCode) =>
                  updateEditedPerson({
                    address: {
                      ...editedPerson.address,
                      zipCode,
                    },
                  })
                }
              />
              <LabelledTextInput
                focus={true}
                value={editedPerson?.address?.city ?? ""}
                label="Ort"
                className="mb-2"
                errors={validationErrors.for("address.city")}
                onChange={(city) => updateEditedPerson({ address: { ...editedPerson.address, city } })}
              />
              <LabelledDateInput
                className="mb-2"
                label="Födelsedatum"
                includeTime={false}
                errors={validationErrors.for("birthDate.birthDate")}
                value={person?.birthDate?.birthDate ?? ""}
                onChange={(birthDate) => updateEditedPerson({ birthDate: { ...editedPerson.birthDate, birthDate } })}
              />
              <LabelledSelect
                focus={true}
                value={groupOptions.find((option) => option.id === editedPerson?.group)}
                label="Grupp"
                className="mb-2"
                options={groupOptions}
                onChange={(option) => updateEditedPerson({ group: option?.id as Group })}
              />
              <LabelledSelect
                focus={true}
                value={roleOptions.find((option) => option.id === editedPerson?.role)}
                label="Roll"
                disabled={!loggedInPersonIsAdmin}
                className="mb-2"
                options={roleOptions}
                errors={validationErrors.for("role")}
                onChange={(option) => updateEditedPerson({ role: option?.id as Role })}
              />
            </div>
            <div className="mt-3 d-flex flex-wrap gap-1 justify-content-end">
              <ActionButton
                label="Ta bort"
                disabled={!editedPerson?.allowedToEdit}
                variant="secondary"
                className="btn-danger"
                onClick={() => setShowConfirmRemoveModal(true)}
              />
              <ActionButton label="Avbryt" variant="secondary" onClick={() => onClose()} />
              <ActionButton
                label="Spara"
                disabled={!isAllowedToEdit()}
                onClick={async () => {
                  const validationResult = await onValidatePerson(editedPerson);
                  setValidationErrors(validationResult);
                  if (!validationResult.hasErrors()) {
                    if (editMode) {
                      console.log("onUpdatePerson: ");
                      onUpdatePerson(editedPerson as Person, selectedImage as File);
                      onClose();
                    } else {
                      console.log("onCreatePerson: ");
                      onCreatePerson(editedPerson as Person, selectedImage as File);
                      onClose();
                    }
                  }
                }}
              />
            </div>
          </>
        ) : undefined}
      </StackLayout>
      <ConfirmRemoveModal
        nameOfTheThingToRemove={"personen"}
        show={showConfirmRemoveModal}
        confirmAction={() => {
          onRemovePerson(editedPerson.uuid as string);
          setShowConfirmRemoveModal(false);
          onClose();
        }}
        dismissAction={() => setShowConfirmRemoveModal(false)}
      />
    </div>
  );
};
