import { StackLayout } from "../layout/StackLayout";
import React from "react";
import { ValidationErrors } from "../../backend/ValidationErrors";

interface ErrorFeedbackTextProps {
  errors?: ValidationErrors;
  show: boolean;
}

export const ErrorFeedbackText: React.FC<ErrorFeedbackTextProps> = (props) => {
  const { errors, show } = props;

  return (
    <main>
      {show ? (
        <StackLayout gap={0}>
          {errors?.errors.map((e) => (
            <i className={"text-danger"} style={{ fontSize: "0.8rem" }}>
              {e.message}
            </i>
          ))}
        </StackLayout>
      ) : null}
    </main>
  );
};
