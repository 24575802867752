import React from "react";
import { TextInputProps } from "./LabelledTextInput";

export const LabelledTextArea: React.FC<TextInputProps> = (props) => {
  const { focus, value, label, onChange = () => undefined, className } = props;
  return (
    <>
      <label className={`${className} input`}>
        <textarea
          className="input__field form-control form-control-sm"
          autoFocus={focus}
          placeholder={label}
          defaultValue={value}
          onChange={(e) => {
            onChange(e.currentTarget.value);
            return false;
          }}
        />
        <span className="input__label">{label}</span>
      </label>
    </>
  );
};
