import { useBackendApi } from "../../common/backend/backendApi";
import { useAppState } from "../../App.state";
import { useEffect } from "react";
import { map, sortBy } from "lodash";
import { dateString } from "../../common/date";

export function LatestScheduledEvents() {
  const backendApi = useBackendApi();
  const appState = useAppState().state;

  useEffect(() => {
    backendApi.calendarEvents
      .list()
      .then(() => console.debug("Loaded calendar events"))
      .catch((error) => {
        console.error("Failed to load calendar events", error);
        return null;
      });
  }, [backendApi.calendarEvents]);

  if (appState.loggedInPerson.loading || appState.calendarEvents.loading) {
    return <div>Laddar de senaste händelserna...</div>;
  }

  const calendarEvents = sortBy(appState.calendarEvents.value?.slice(0, 5), (calEvent) => calEvent.eventDate);

  return (
    <>
      <div className="card">
        <div className="card-header">Kalender</div>
        <div className="card-body d-flex flex-column gap-1 align-items-start">
          {map(calendarEvents, (calEvent) => (
            <div>
              <a href={`/calendar/${calEvent.uuid}`} key={calEvent.uuid}>
                {calEvent.title} {dateString(calEvent.eventDate)}
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
