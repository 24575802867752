import { find } from "lodash";
import React, { useState } from "react";
import { Loadable } from "../../../../App.state";
import { BasicLinkData, LinkType, SavedLink } from "../../../../common/backend/model/Link.model";
import { LabelledSelect } from "../../../../common/components/labelled-inputs/LabelledSelect";
import { FileLinkEditor } from "./FileLinkEditor";
import { UrlLinkEditor } from "./UrlLinkEditor";
import { ValidationErrors } from "../../../../common/backend/ValidationErrors";

export interface LinkEditorProps {
  linkUUID?: string;
  savedLink?: SavedLink;
  links: Loadable<Array<SavedLink>>;
  onCreateLink?: (linkData: BasicLinkData) => void;
  onCreateFileLink?: (linkData: BasicLinkData, file: File) => void;
  onUpdateLink?: (uuid: string, linkData: BasicLinkData) => void;
  onRemoveLink?: (uuid: string, linkData: BasicLinkData) => void;
  onValidateUrlLink?: (linkData: BasicLinkData) => Promise<ValidationErrors>;
  onValidateFileLink?: (linkData: BasicLinkData) => Promise<ValidationErrors>;
  onClose?: () => void;
}

export const LinkEditor: React.FC<LinkEditorProps> = (props) => {
  const { savedLink } = props;

  const options = [
    { id: "URL_LINK", label: "Länk till extern sida" },
    { id: "FILE_LINK", label: "Länk till uppladdad fil" },
  ];

  const [selectedComponent, setSelectedComponent] = useState<LinkType | "NO_SELECTION">(
    savedLink ? (savedLink.type === "URL_LINK" ? "URL_LINK" : "FILE_LINK") : "NO_SELECTION"
  );
  return (
    <div className="p-2 d-flex flex-column gap-2">
      {!savedLink?.uuid ? (
        <LabelledSelect
          label="Typ av länk"
          value={find(options, (o) => o.id === savedLink?.type)}
          onChange={(value) => setSelectedComponent(value?.id === "URL_LINK" ? "URL_LINK" : "FILE_LINK")}
          options={options}
        />
      ) : null}
      {selectedComponent === "NO_SELECTION" ? null : selectedComponent === "URL_LINK" ? (
        <UrlLinkEditor {...props} />
      ) : (
        <FileLinkEditor {...props} />
      )}
    </div>
  );
};
