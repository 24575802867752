import { filter } from "lodash";
import React, { Dispatch, useContext } from "react";
import { v4 as uuid } from "uuid";
import { SavedLink } from "./common/backend/model/Link.model";
import { Person } from "./common/backend/model/Person.model";
import { SavedPoll } from "./common/backend/model/Poll.model";
import { Notification } from "./pages/rootpage/Notifications";
import { Album } from "./common/backend/model/Album.model";
import { CalendarEvent } from "./pages/calendar/CalenderEvent.model";
import { ImageOfTheDay, Media } from "./common/backend/model/Media.model";
import { Stock } from "./common/backend/model/Stock.model";

export interface Identifiable {
  uuid: string;
}

export type IdentifiableNotification = Notification & Identifiable;

export interface Loadable<T> {
  loading?: boolean;
  value?: T;
}

export interface AppState {
  loggedInPerson: Loadable<Person>;
  persons: Loadable<Array<Person>>;
  allSavedPolls: Loadable<Array<SavedPoll>>;
  activePolls: Loadable<Array<SavedPoll>>;
  links: Loadable<Array<SavedLink>>;
  newestLinks: Loadable<Array<SavedLink>>;
  albums: Loadable<Array<Album>>;
  medias: Loadable<Array<Media>>;
  imageOfTheDay: Loadable<ImageOfTheDay>;
  notifications: Array<IdentifiableNotification>;
  ongoingActivities: {
    [uuid: string]:
      | {
          activityName: string;
        }
      | undefined;
  };
  calendarEvents: Loadable<Array<CalendarEvent>>;
  oldCalendarEvents: Loadable<Array<CalendarEvent>>;
  stock: Loadable<Stock>;
}

export interface SetLoggedInPerson {
  type: "SetLoggedInPerson";
  person: Loadable<Person>;
}

export interface SetPersons {
  type: "SetPersons";
  persons: Loadable<Array<Person>>;
}

export interface SetAllSavedPolls {
  type: "SetAllSavedPolls";
  polls: Loadable<Array<SavedPoll>>;
}

export interface SetActivePolls {
  type: "SetActivePolls";
  polls: Loadable<Array<SavedPoll>>;
}

export interface AddNotification {
  type: "AddNotification";
  notification: Notification;
}

export interface SetLinks {
  type: "SetLinks";
  links: Loadable<Array<SavedLink>>;
}

export interface SetNewestLinks {
  type: "SetNewestLinks";
  links: Loadable<Array<SavedLink>>;
}

export interface SetAlbums {
  type: "SetAlbums";
  albums: Loadable<Array<Album>>;
}

export interface SetMedias {
  type: "SetMedias";
  medias: Loadable<Array<Media>>;
}

export interface SetImageOfTheDay {
  type: "SetImageOfTheDay";
  image: Loadable<ImageOfTheDay>;
}

export interface RemoveNotification {
  type: "RemoveNotification";
  notification: IdentifiableNotification;
}

export interface StartOngoingActivity {
  type: "StartOngoingActivity";
  activity: {
    activityId: string; // UUID
    name: string;
  };
}

export interface FinishOngoingActivity {
  type: "FinishOngoingActivity";
  activityId: string; // UUID
}

export interface SetCalendarEvents {
  type: "SetCalendarEvents";
  data: Loadable<Array<CalendarEvent>>;
}
export interface SetOldCalendarEvents {
  type: "SetOldCalendarEvents";
  data: Loadable<Array<CalendarEvent>>;
}

export interface SetStockData {
  type: "SetStockData";
  stock: Loadable<Stock>;
}

export type AppAction =
  | SetLoggedInPerson
  | SetPersons
  | SetAllSavedPolls
  | SetActivePolls
  | SetLinks
  | SetNewestLinks
  | AddNotification
  | SetAlbums
  | SetMedias
  | SetImageOfTheDay
  | RemoveNotification
  | StartOngoingActivity
  | FinishOngoingActivity
  | SetCalendarEvents
  | SetOldCalendarEvents
  | SetStockData;

export const INITIAL_STATE: AppState = {
  loggedInPerson: {},
  persons: {},
  allSavedPolls: {},
  activePolls: {},
  links: {},
  newestLinks: {},
  albums: {},
  medias: {},
  imageOfTheDay: {},
  notifications: [],
  ongoingActivities: {},
  calendarEvents: {},
  oldCalendarEvents: {},
  stock: {},
};

export function appReducer(currentState: AppState, action: AppAction): AppState {
  switch (action.type) {
    case "SetLoggedInPerson":
      return { ...currentState, loggedInPerson: action.person };
    case "SetPersons":
      return { ...currentState, persons: action.persons };
    case "SetAllSavedPolls":
      return { ...currentState, allSavedPolls: action.polls };
    case "SetActivePolls":
      return { ...currentState, activePolls: action.polls };
    case "SetLinks":
      return { ...currentState, links: action.links };
    case "SetNewestLinks":
      return { ...currentState, newestLinks: action.links };
    case "SetAlbums":
      return { ...currentState, albums: action.albums };
    case "SetMedias":
      return { ...currentState, medias: action.medias };
    case "SetImageOfTheDay":
      return { ...currentState, imageOfTheDay: action.image };
    case "AddNotification":
      return {
        ...currentState,
        notifications: [...currentState.notifications, { uuid: uuid(), ...action.notification }],
      };
    case "RemoveNotification":
      return {
        ...currentState,
        notifications: filter(currentState.notifications, (n) => n.uuid !== action.notification.uuid),
      };
    case "StartOngoingActivity":
      return {
        ...currentState,
        ongoingActivities: {
          ...currentState.ongoingActivities,
          [action.activity.activityId]: {
            activityName: action.activity.name,
          },
        },
      };
    case "FinishOngoingActivity":
      // Nifty way to remove a key using destructuring
      const { [action.activityId]: remove, ...newOngoingActivities } = currentState.ongoingActivities;
      return {
        ...currentState,
        ongoingActivities: {
          ...newOngoingActivities,
        },
      };
    case "SetCalendarEvents":
      return {
        ...currentState,
        calendarEvents: action.data,
      };
    case "SetOldCalendarEvents":
      return {
        ...currentState,
        oldCalendarEvents: action.data,
      };
    case "SetStockData":
      return {
        ...currentState,
        stock: action.stock,
      };
    default:
      return currentState;
  }
}

export interface AppContextModel {
  state: AppState;
  dispatch: Dispatch<AppAction>;
}

const DEFAULT_APP_CONTEXT: AppContextModel = {
  state: INITIAL_STATE,
  dispatch: () => {},
};

export const AppContext = React.createContext<AppContextModel>(DEFAULT_APP_CONTEXT);

export function useAppState(): {
  dispatch: (value: AppAction) => void;
  state: AppState;
} {
  const { state, dispatch } = useContext(AppContext);
  return { state, dispatch };
}
