import React, { useState } from "react";
import { componenIdGenerator } from "../../../utils/id-util";
import "./LabelledInput.scss";
import { LabelledInputBaseProps } from "./LabelledInputBaseProps";
import { ErrorFeedbackProps } from "../../validation/WithErrorFeedback";

export interface LabelledInputProps extends LabelledInputBaseProps, ErrorFeedbackProps {
  value?: string;
  type?: "date" | "datetime-local" | "text" | "number" | "password";
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const LabelledInput: React.FC<LabelledInputProps> = ({
  focus,
  value,
  label,
  type,
  onChange = () => undefined,
  className,
  onBlur = () => undefined,
  onModified = () => undefined,
  errorBorderClass,
  disabled,
}) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);
  const [active, setActive] = useState<boolean>(false);
  const labelInBorder = !!currentValue || active;
  const inputId = `input-${componenIdGenerator.generateId()}`;

  return (
    <>
      <div className="position-relative" style={{ paddingTop: "5px" }}>
        <label
          htmlFor={inputId}
          className={`input-label position-absolute ${labelInBorder ? "label-in-border" : "label-in-input text-muted"}`}
        >
          {label}
        </label>
        <input
          id={inputId}
          className={`${errorBorderClass} form-control form-control-sm shadow-none position-relative 
          ${className} 
          ${labelInBorder ? "label-in-border" : "label-in-input"}`}
          type={type}
          autoFocus={focus}
          defaultValue={currentValue}
          disabled={disabled}
          onClick={() => {
            setActive(true);
            return false;
          }}
          onBlur={(e) => {
            setActive(false);
            onBlur(e);
            return false;
          }}
          onChange={(e) => {
            setCurrentValue(e.currentTarget.value);
            onChange(e.currentTarget.value);
            onModified();
            return false;
          }}
        />
      </div>
    </>
  );
};
