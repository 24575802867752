import { isEmpty } from "lodash";

export function dateString(date: Date | string | undefined, defaultValue?: string): string | undefined {
  if (!date) {
    return defaultValue;
  }

  if (typeof date === "string") {
    return new Date(date).toLocaleDateString();
  }

  return date.toLocaleDateString();
}

/**
 * Utility class for formatting Date objects in a specific string format suitable for use in
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date date}
 * and
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local datetime-local} input types.
 *
 * @namespace DateInputUtil
 */
export const DateInputUtil = {
  /**
   * @param dateValue - The date value to format.
   * @param includeTime - Whether to include time or not in the formatted output.
   *
   * @returns - The formatted date value, e.g.
   */
  format: function (dateValue: Date | string | undefined, includeTime: boolean | undefined) {
    return includeTime ? this.formatDateTime(dateValue) : this.formatDate(dateValue);
  },

  formatDate: function (dateString: any) {
    return isEmpty(dateString)
      ? undefined
      : new Intl.DateTimeFormat("sv-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(dateString));
  },

  /**
   * @param {Date | string | undefined} date - The date to be converted.
   * @returns The formatted date-time string in the following format `YYYY-MM-DDThh:mm`.
   *
   * Returns undefined if the input is undefined.
   */
  formatDateTime: function (date: Date | string | undefined) {
    switch (typeof date) {
      case "object":
        return date.toISOString().substring(0, 16);
      case "string":
        // Suffix with timezone offset so that date input is fixed, i.e. not dependent on the local time zone of the machine.
        return !isEmpty(date) ? new Date(`${date + "Z"}`).toISOString().substring(0, 16) : undefined;
      default:
        return undefined;
    }
  },
};
