import { find } from "lodash";
import React, { useState } from "react";
import { Loadable } from "../../../../App.state";
import { BasicLinkData, Category, SavedLink } from "../../../../common/backend/model/Link.model";
import { ActionButton } from "../../../../common/components/buttons/ActionButton";
import { LabelledSelect } from "../../../../common/components/labelled-inputs/LabelledSelect";
import { LabelledTextInput } from "../../../../common/components/labelled-inputs/LabelledTextInput";
import { ConfirmRemoveModal } from "../../../../common/components/modal/ConfirmRemoveModal";
import { ValidationErrors, validationErrorsEmpty } from "../../../../common/backend/ValidationErrors";

export interface LinkEditorProps {
  linkUUID?: string;
  savedLink?: SavedLink;
  links: Loadable<Array<SavedLink>>;
  onCreateLink?: (linkData: BasicLinkData) => void;
  onUpdateLink?: (uuid: string, linkData: BasicLinkData) => void;
  onRemoveLink?: (uuid: string, linkData: BasicLinkData) => void;
  onValidateUrlLink?: (linkData: BasicLinkData) => Promise<ValidationErrors>;
  onClose?: () => void;
}

export const UrlLinkEditor: React.FC<LinkEditorProps> = (props: LinkEditorProps) => {
  function updateEditedLink(fields: Partial<BasicLinkData>) {
    setEditedLink((prevValue) => {
      const newValue = {
        ...prevValue,
        ...fields,
      };
      return newValue;
    });
    setModified(true);
  }

  function addProtocolIfMissing(link: BasicLinkData) {
    if (link.url && !link.url.startsWith("http://") && !link.url.startsWith("https://")) {
      link.url = "http://" + link.url;
    }
  }

  const {
    linkUUID,
    savedLink,
    onCreateLink = () => undefined,
    onRemoveLink = () => undefined,
    onUpdateLink = () => undefined,
    onClose = () => undefined,
    onValidateUrlLink = () => Promise.resolve(validationErrorsEmpty()),
  } = props;

  const [editMode] = useState(!!linkUUID);
  const [editedLink, setEditedLink] = useState<Partial<BasicLinkData>>(savedLink || { type: "URL_LINK" });
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const [modified, setModified] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(validationErrorsEmpty());
  const options = [
    { id: Category.WEEKLY_LETTER, label: "Veckobrev" },
    { id: Category.POLICY_DOCUMENT, label: "Policy & kvalité" },
    { id: Category.CONTACTOR, label: "Contactor" },
    { id: Category.CAG, label: "CAG" },
  ];

  return (
    <>
      <div>
        <LabelledTextInput
          focus={true}
          value={editedLink?.url}
          label="Url"
          className="mb-2"
          errors={validationErrors.for("url")}
          onChange={(url) => updateEditedLink({ url })}
        />

        <LabelledTextInput
          focus={true}
          value={editedLink?.title}
          label="Titel"
          className="mb-2"
          errors={validationErrors.for("title")}
          onChange={(title) => updateEditedLink({ title })}
        />

        <LabelledTextInput
          focus={true}
          value={editedLink?.description}
          label="Beskrivning"
          className="mb-2"
          onChange={(description) => updateEditedLink({ description })}
        />

        <LabelledSelect
          label="Kategori"
          focus={true}
          value={find(options, (o) => o.id === editedLink?.category)}
          options={options}
          errors={validationErrors.for("category")}
          onChange={(o) => updateEditedLink({ category: o?.id as Category })}
          className="mb-2"
        />

        <div className="mt-3 d-flex flex-wrap gap-1 justify-content-end">
          {savedLink ? (
            <ActionButton
              label="Ta bort"
              variant="secondary"
              className="btn-danger"
              onClick={() => setShowConfirmRemoveModal(true)}
            />
          ) : null}
          <ActionButton label="Avbryt" variant="secondary" onClick={() => onClose()} />
          <ActionButton
            label="Spara"
            disabled={!modified}
            onClick={async () => {
              addProtocolIfMissing(editedLink as BasicLinkData);
              const validationResult = await onValidateUrlLink(editedLink as BasicLinkData);
              setValidationErrors(validationResult);
              if (!validationResult.hasErrors()) {
                if (editMode) {
                  onUpdateLink(linkUUID as string, editedLink as BasicLinkData);
                } else {
                  onCreateLink(editedLink as BasicLinkData);
                }
              }
            }}
          />
        </div>
      </div>
      <ConfirmRemoveModal
        nameOfTheThingToRemove={"länken"}
        show={showConfirmRemoveModal}
        confirmAction={() => {
          if (editMode) {
            onRemoveLink(linkUUID as string, editedLink as BasicLinkData);
          }
          setShowConfirmRemoveModal(false);
        }}
        dismissAction={() => setShowConfirmRemoveModal(false)}
      />
    </>
  );
};
