import React from "react";
import { LabelledInput, LabelledInputProps } from "./new-input/LabelledInput";
import { withErrorFeedback } from "../validation/WithErrorFeedback";

export interface TextInputProps extends LabelledInputProps {
  disabled?: boolean;
}

const Component: React.FC<TextInputProps> = (props) => {
  const { type = "text", disabled = false } = props;
  return (
    <>
      <LabelledInput {...props} type={type}>
        {" "}
        disabled={disabled}
      </LabelledInput>
    </>
  );
};

export const LabelledTextInput = withErrorFeedback(Component);
