export enum Status {
  POSITIVE = "POSITIVE",
  UNCHANGED = "UNCHANGED",
  NEGATIVE = "NEGATIVE",
}

export interface Stock {
  name: string;
  value: string;
  status: Status;
}

export function actualStockValue(stock: Stock): string {
  return `${stock?.value}`;
}

export class StockModel implements Stock {
  constructor(public readonly name: string, public readonly value: string, public readonly status: Status) {}

  toString(): string {
    return `StockModel(name: ${this.name}, stockValue: ${this.value}, status: ${this.status})`;
  }
}
