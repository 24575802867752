import React, { useState } from "react";
import { AlbumData, IncompleteAlbumData } from "../../../common/backend/model/Album.model";
import { LabelledTextArea } from "../../../common/components/labelled-inputs/LabelledTextArea";
import { FileSelect } from "../../../common/components/file-upload/FileSelect";
import { LabelledTextInput } from "../../../common/components/labelled-inputs/LabelledTextInput";
import { ActionButton } from "../../../common/components/buttons/ActionButton";
import { ValidationErrors, validationErrorsEmpty } from "../../../common/backend/ValidationErrors";
import { ConfirmRemoveModal } from "../../../common/components/modal/ConfirmRemoveModal";

export interface AlbumEditorProps {
  albumUUID?: string;
  savedAlbum?: AlbumData;
  onCreateAlbum?: (albumData: AlbumData, files: File[]) => void;
  onUpdateAlbum?: (uuid: string, albumData: AlbumData, files: File[]) => void;
  onRemoveAlbum?: (uuid: string) => void;
  onClose?: () => void;
  onValidateAlbum?: (albumData: IncompleteAlbumData) => Promise<ValidationErrors>;
}

export const AlbumEditor: React.FC<AlbumEditorProps> = (props: AlbumEditorProps) => {
  const {
    albumUUID,
    savedAlbum,
    onCreateAlbum = () => undefined,
    onRemoveAlbum = () => undefined,
    onUpdateAlbum = () => undefined,
    onClose = () => undefined,
    onValidateAlbum = () => Promise.resolve(validationErrorsEmpty()),
  } = props;
  const [editMode] = useState(!!albumUUID);
  const [editedAlbum, setEditedAlbum] = useState<Partial<AlbumData>>(savedAlbum || {});
  const [modified, setModified] = useState(false);
  const [files, setFiless] = useState<File[]>();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(validationErrorsEmpty());
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);

  function updateEditedAlbum(fields: Partial<AlbumData>) {
    setEditedAlbum((prevValue) => {
      const newValue = {
        ...prevValue,
        ...fields,
      };
      setModified(true);
      return newValue;
    });
  }

  return (
    <>
      <div>
        <LabelledTextInput
          focus={true}
          value={editedAlbum?.name}
          label="Namn"
          className="mb-2"
          errors={validationErrors.for("name")}
          onChange={(title) => updateEditedAlbum({ name: title })}
        />

        <LabelledTextArea
          focus={true}
          value={editedAlbum?.description}
          label="Beskrivning"
          className="mb-2"
          onChange={(description) => updateEditedAlbum({ description })}
        />

        <div className={"d-flex align-items-center gap-2"}>
          <FileSelect
            multiple={true}
            buttonLabel={editMode ? "Ladda upp fler filer" : "Ladda upp filer"}
            mimeTypes={["image/jpeg", "image/png", "video/quicktime", "video/mp4"]}
            onSelectionChange={(files) => {
              setFiless(files);
              setModified(true);
            }}
          />
          {!!files ? (
            <p className={"text-truncate my-0 text-secondary flex-fill"}>
              {files.length}
              {files.length > 1 ? " filer valda" : " fil vald"}
            </p>
          ) : null}
        </div>

        <div className="mt-3 d-flex flex-wrap gap-1 justify-content-end">
          {editMode ? (
            <ActionButton
              label="Ta bort"
              variant="secondary"
              className="btn-danger"
              onClick={() => setShowConfirmRemoveModal(true)}
            />
          ) : null}
          <ActionButton label="Avbryt" variant="secondary" onClick={() => onClose()} />
          <ActionButton
            label="Spara"
            disabled={!modified}
            onClick={async () => {
              const validationResult = await onValidateAlbum(editedAlbum);
              setValidationErrors(validationResult);
              if (!validationResult.hasErrors()) {
                if (editMode) {
                  onUpdateAlbum(albumUUID as string, editedAlbum as AlbumData, !files ? [] : files);
                } else {
                  onCreateAlbum(editedAlbum as AlbumData, !files ? [] : files);
                }
              }
            }}
          />
        </div>

        <ConfirmRemoveModal
          nameOfTheThingToRemove={"albumet"}
          show={showConfirmRemoveModal}
          confirmAction={() => {
            onRemoveAlbum(albumUUID as string);
            setShowConfirmRemoveModal(false);
          }}
          dismissAction={() => setShowConfirmRemoveModal(false)}
        />
      </div>
    </>
  );
};
