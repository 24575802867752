import { Loadable } from "../../App.state";
import { Person } from "../../common/backend/model/Person.model";
import { PersonCard } from "./PersonCard";
import PersonListFilter from "./PersonListFilter";
import { sortBy } from "lodash";

export interface PersonListProps {
  persons: Loadable<Array<Person>>;
  onOpenEditPerson: (person: Person) => void;
  onOpenViewPerson: (person: Person) => void;
  filterBy: (groupName: string | null) => void;
}
export const PersonList: React.FC<PersonListProps> = (props) => {
  const { persons, onOpenEditPerson, onOpenViewPerson, filterBy } = props;

  if (persons.loading || !persons.value) {
    return null;
  }

  const sortPersons = (persons: Array<Person>): Array<Person> => {
    return sortBy(persons, ["firstName", "lastName"]);
  };

  return (
    <main>
      <div className="fw-lighter text-muted text-end">
        Filtrera:
        <PersonListFilter doFilter={() => filterBy(null)} label="Alla" /> |
        <PersonListFilter doFilter={() => filterBy("JAVA")} label="Java" /> |
        <PersonListFilter doFilter={() => filterBy("TEST")} label="Test" /> |
        <PersonListFilter doFilter={() => filterBy("ADMIN")} label="Admin" /> |
      </div>
      {sortPersons(persons.value).map((p) => (
        <PersonCard
          key={p.uuid}
          person={p}
          onOpenEditPerson={onOpenEditPerson}
          onOpenViewPerson={() => {
            onOpenViewPerson(p);
          }}
        />
      ))}
    </main>
  );
};
