import { Offcanvas } from "react-bootstrap";
import { OffcanvasPlacement } from "react-bootstrap/Offcanvas";
import React from "react";

export function inOffCanvasHOC<P>(
  WrappedComponent: React.FunctionComponent<P>,
  placement: OffcanvasPlacement,
  title: string
) {
  return (props: P & { show: boolean; onHide: () => void }) => (
    <Offcanvas show={props.show} placement={placement} onHide={props.onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <WrappedComponent {...props} />
      </Offcanvas.Body>
    </Offcanvas>
  );
}
