import { isEmpty, map } from "lodash";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ActionButton } from "../buttons/ActionButton";
import "./LabelledInput.scss";
import { LabelledTextInput } from "./LabelledTextInput";
import { LabelledInputBaseProps } from "./new-input/LabelledInputBaseProps";
import { ErrorFeedbackProps, withErrorFeedback } from "../validation/WithErrorFeedback";

export interface AlternativesInputProps extends LabelledInputBaseProps, ErrorFeedbackProps {
  label: string;
  value?: Array<string>;
  onChange?: (newValue: Array<string>) => void;
}

const Component: React.FC<AlternativesInputProps> = (props) => {
  function addAlternative(addedAlternative: string) {
    setInternalValue((preInternalValue) => {
      const parsedAlternatives = addedAlternative.split(/[;]/); // It is possible to input multiple alternatives by separating them with comma, vertical bar or semicolomn
      const newInternalValue = [...preInternalValue, ...parsedAlternatives];
      onChange(newInternalValue);
      onModified();
      return newInternalValue;
    });
    setShowModal(false);
  }

  function removeAlternative(removedAlternative: string) {
    setInternalValue((preInternalValue) => {
      const newInternalValue = preInternalValue.filter((e) => e !== removedAlternative);
      onChange(newInternalValue);
      onModified();
      return newInternalValue;
    });
  }

  const {
    label,
    className,
    value = [],
    onChange = () => undefined,
    onModified = () => undefined,
    errorBorderClass,
  } = props;

  const [internalValue, setInternalValue] = useState(value);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={`${className} input`}>
        <div className={`${errorBorderClass} input__field border rounded-1`} style={{ minHeight: "2.125rem" }}>
          <div className="d-flex align-items-start">
            <div className={isEmpty(internalValue) ? "input__label-visible" : "input__label-on-border"}>{label}</div>
            <div className="flex-grow-1 d-flex flex-wrap mt-1">
              {map(internalValue, (a) => (
                <div className="badge outline bg-secondary me-1 mb-1 d-flex flex-wrap  align-items-center" key={a}>
                  <span>{a}</span>
                  <span>
                    <button
                      className="btn-close ms-1"
                      style={{ height: "0.3rem" }}
                      onClick={() => removeAlternative(a)}
                    />
                  </span>
                </div>
              ))}
            </div>
            <div className="flex-grow-0 d-flex flex-wrap justify-content-end my-1">
              <ActionButton variant="outline-primary" size="sm" onClick={() => setShowModal(true)}>
                Lägg&nbsp;till
              </ActionButton>
            </div>
          </div>
        </div>
        <AddAlternativeModal
          show={showModal}
          existingAlternatives={internalValue}
          onCloseRequested={() => setShowModal(false)}
          onSave={addAlternative}
        />
      </div>
    </>
  );
};

export const AlternativesInput = withErrorFeedback(Component);

const AddAlternativeModal = (props: {
  show: boolean;
  existingAlternatives: Array<string>;
  onCloseRequested: () => void;
  onSave: (value: string) => void;
}) => {
  function valueAlreadyExisting(value: string): boolean {
    return !!existingAlternatives.find((ea) => ea === value);
  }

  const { show, existingAlternatives = [], onCloseRequested, onSave } = props;

  const [value, setValue] = useState<string>("");

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setValue("");
          onCloseRequested();
        }}
      >
        <Modal.Body className="p-1">
          <LabelledTextInput
            label="Ange alternativ (separerade med ';')"
            focus={true}
            value={value}
            onChange={setValue}
          />
          <div className="d-flex flex-wrap justify-content-end gap-1 pt-2">
            <ActionButton
              variant="secondary"
              size="sm"
              onClick={() => {
                setValue("");
                onCloseRequested();
              }}
            >
              Avbryt
            </ActionButton>
            <ActionButton
              variant="primary"
              size="sm"
              type="button"
              disabled={!value || valueAlreadyExisting(value)}
              onClick={() => {
                onSave(value);
                setValue("");
              }}
            >
              Spara
            </ActionButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
