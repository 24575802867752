import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import "./NestedIconButton.scss";

/**
 * This component is used to create a clickable icon inside a card component.
 * It is required that the parent component is a card for the CSS to work correctly.
 *
 * @param icon The icon to display
 * @param positionAbsolute Whether the icon should have a fixed position on the card, independent of the card's content.
 * When positioned absolutely its position can be set by the absolutePosition parameter.
 * @param absolutePosition The position of the icon on the card when positionAbsolute is set to true. Use "custom" to
 * set a custom position through the className parameter.
 * @param appearOnlyOnCardHover Whether the icon should appear only when the parent card is hovered.
 * @param shadow Whether the icon should have a shadow. Good if the parent card has a noisy background.
 */

interface NestedIconButtonProps {
  icon: IconDefinition;
  onClick?: () => void;
  size?: "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x";
  title?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  positionAbsolute?: boolean;
  absolutePosition?: "upper-left" | "upper-right" | "lower-left" | "lower-right" | "custom";
  appearOnlyOnCardHover?: boolean;
  shadow?: boolean;
}

export const NestedIconButton: FC<NestedIconButtonProps> = (props) => {
  const {
    icon,
    onClick = () => undefined,
    size = "1x",
    title = "",
    onMouseLeave,
    onMouseEnter,
    className,
    positionAbsolute = false,
    absolutePosition = "lower-right",
    appearOnlyOnCardHover = false,
    shadow = false,
  } = props;

  return (
    <FontAwesomeIcon
      icon={icon}
      size={size}
      className={`
      nested-icon-button 
      click-target
      ${className}
      ${positionAbsolute ? "position-absolute" : ""}
      ${absolutePosition === "custom" ? "" : absolutePosition}
      ${appearOnlyOnCardHover ? "appear-on-hover" : ""}
      ${shadow ? "shadow" : ""}
      `}
      title={title}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};
