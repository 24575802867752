import { isEmpty, map } from "lodash";
import { useEffect, useState } from "react";
import { useAppState } from "../../App.state";
import { useBackendApi } from "../../common/backend/backendApi";
import { SavedPoll, UpdatePollResponseCommand } from "../../common/backend/model/Poll.model";
import { PollAnswerFormInOffCanvas } from "./PollFormInOffCanvas";

export const ActivePollsForLoggedInPerson: React.FC = () => {
  async function updatePollResponse(pollId: string, updatePollResponseCommand: UpdatePollResponseCommand) {
    await backendApi.polls.updatePollResponse(pollId, updatePollResponseCommand);
    setOpenedPoll(undefined);
  }

  const backendApi = useBackendApi();
  const appState = useAppState().state;
  const [openedPoll, setOpenedPoll] = useState<SavedPoll | undefined>();

  useEffect(() => {
    backendApi.polls.loadActivePolls();
  }, [backendApi.polls]);

  if (
    appState.loggedInPerson.loading ||
    appState.activePolls.loading ||
    !appState.loggedInPerson.value ||
    isEmpty(appState.activePolls.value)
  ) {
    return null;
  }

  return (
    <>
      <div className="card shadow-lg">
        <div className="card-header">Frågor</div>
        <div className="card-body d-flex flex-column gap-1 align-items-start">
          {map(appState.activePolls.value, (p) => (
            <button className="btn btn-link shadow-none" key={p.uuid} onClick={() => setOpenedPoll(p)}>
              {p.heading}
            </button>
          ))}
        </div>
      </div>
      {openedPoll ? (
        <PollAnswerFormInOffCanvas
          show={true}
          loggedInPerson={appState.loggedInPerson.value}
          poll={openedPoll}
          onOpenPollEditor={() => undefined}
          onUpdatePollResponse={updatePollResponse}
          onClose={() => setOpenedPoll(undefined)}
          onHide={() => setOpenedPoll(undefined)}
          onOpenChangeAnswerForm={() => undefined}
        />
      ) : null}
    </>
  );
};
