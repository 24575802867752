import React, { useEffect } from "react";
import { Media, MediaType } from "../../../common/backend/model/Media.model";
import "./MediaViewModal.scss";
import Modal from "react-bootstrap/Modal";
import { faArrowCircleLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MediaViewModalProps {
  show: boolean;
  media: Media;
  onNext: () => void;
  onPrev: () => void;
  onClose: () => void;
}

export const MediaViewModal: React.FC<MediaViewModalProps> = (props: MediaViewModalProps) => {
  const { show, media, onNext, onPrev, onClose } = props;

  function navPrev(e: React.MouseEvent) {
    e.stopPropagation();
    onPrev();
  }

  function navNext(e: React.MouseEvent) {
    e.stopPropagation();
    onNext();
  }

  // Effect to add and remove the event listener
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        onNext();
      } else if (event.key === "ArrowLeft") {
        onPrev();
      }
    };

    if (show) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [show, media, onNext, onPrev]); // Only re-run if `show` changes

  if (!show) return null;

  let mediaElement = null;
  if (media?.mediaType === MediaType.IMAGE) {
    mediaElement = (
      <img
        src={media.fullSizeUrl}
        className="media-content"
        alt={media.metaData?.description || "beskrivning saknas"}
      />
    );
  } else if (media?.mediaType === MediaType.VIDEO) {
    mediaElement = (
      <video src={media.url} controls className="media-content" onClick={(e) => e.stopPropagation()}>
        Video not supported
      </video>
    );
  }

  return (
    <Modal show={show} onHide={onClose} className="custom-modal" centered onClick={onClose} animation={false}>
      <Modal.Body className={"d-flex"}>
        <div
          className={`mobile-nav-field ${media?.mediaType === MediaType.IMAGE ? "image-nav" : "video-nav"} left`}
          onClick={navPrev}
        />
        <FontAwesomeIcon icon={faArrowCircleLeft} size="3x" className="nav-icon left click-target" onClick={navPrev} />

        {mediaElement}

        <FontAwesomeIcon
          icon={faCircleArrowRight}
          size="3x"
          className="nav-icon right click-target"
          onClick={navNext}
        />
        <div
          className={`mobile-nav-field ${media?.mediaType === MediaType.IMAGE ? "image-nav" : "video-nav"} right`}
          onClick={navNext}
        />
      </Modal.Body>
    </Modal>
  );
};
