import React, { useState } from "react";
import { FileSelect } from "../../common/components/file-upload/FileSelect";
import { head } from "lodash";
import { Person } from "../../common/backend/model/Person.model";

interface ProfileImageEditorProps {
  person?: Partial<Person>;
  onImageSelect?: (image: File) => void;
}

export const ProfileImageEditor: React.FC<ProfileImageEditorProps> = (props: ProfileImageEditorProps) => {
  const { person, onImageSelect } = props;
  const defaultImage = "/assets/default-person.png";
  const [selectedImage, setSelectedImage] = useState(person?.imageLink ?? defaultImage);

  return (
    <div className="d-flex flex-wrap justify-content-center my-3">
      <div className="d-flex flex-column align-items-center mt-3">
        <img src={selectedImage} alt="User's profile" className="rounded-pill w-50 h-75 mb-3" />
        <FileSelect
          className="bottom-0 mb-0"
          multiple={false}
          buttonLabel={"Ändra profilbild"}
          mimeTypes={["image/jpg"]}
          onSelectionChange={(files) => {
            const file = head(files);
            if (file) {
              setSelectedImage(URL.createObjectURL(file));
              onImageSelect?.(file);
            }
          }}
        />
      </div>
    </div>
  );
};
