import React from "react";

import { Breadcrumb } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Person } from "../../common/backend/model/Person.model";
import { Stock } from "../../common/backend/model/Stock.model";
import { StockDisplay } from "../../common/components/stock-display/StockDisplay";
import { UserSymbols } from "../../common/components/user-symbol/UserSymbols";

export interface TopBarProps {
  person: Person;
  stockData?: Stock;
}

export const TopBar: React.FC<TopBarProps> = ({ person, stockData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathElements = location.pathname.split("/").filter(Boolean).filter(notUuid);

  const handleEdit = () => {
    navigate("/persons/" + person.uuid);
  };

  return (
    <div className="sticky-top d-flex flex-wrap align-items-center w-100 p-2 bg-dark text-light" id="hem-top-bar">
      <CagLogo />
      <Breadcrumb className="flex-grow-1">
        <Breadcrumb.Item onClick={() => navigate("/")} active={pathElements.length === 0}>
          Hemma
        </Breadcrumb.Item>
        {pathElements.map((pe, i) => (
          <Breadcrumb.Item active={i === pathElements.length - 1} key={pe} onClick={() => navigate("/" + pe)}>
            {displayNameFor(pe)}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <StockDisplay stockData={stockData} />
      <UserSymbols person={person} onEdit={handleEdit} />
    </div>
  );
};

const CagLogo: React.FC = () => (
  <a href="https://www.cag.se" target="_blank" rel="noopener noreferrer">
    <div className="ms-2 me-3">
      <svg xmlns="http://www.w3.org/2000/svg" className="pt-1" viewBox="0 0 121.91 59.78" height="1.25rem">
        <mask id="cag-logo-mask-634ab31819fde">
          <rect fill="white" x="0" y="0" width="121.91" height="59.78"></rect>
          <circle id="cag-logo-eye-634ab31819fdf" fill="black" cx="99.44" cy="20.31" r="8.29"></circle>
        </mask>
        <path
          fill="currentColor"
          d="M63.73 4.72a18.7 18.7 0 1 0-9.98 34.5 18.6 18.6 0 0 0 9.98-2.9c.67.9.35 1.5 1.56 1.5h7.69V3.21h-7.69c-1.21 0-.89.6-1.56 1.5ZM53.75 28.8a8.28 8.28 0 1 1 8.28-8.28 8.28 8.28 0 0 1-8.28 8.28Z"
        ></path>
        <circle fill="currentColor" cx="4.14" cy="4.14" r="4.14" transform="translate(113.63)"></circle>
        <circle fill="currentColor" cx="99.45" cy="20.3" r="18.7" mask="url(#cag-logo-mask-634ab31819fde)"></circle>
        <path
          fill="currentColor"
          d="M99.44 49.36a8.29 8.29 0 0 1-8.22-7.32 1.05 1.05 0 0 0-1.03-.96h-8.35a1.06 1.06 0 0 0-1.07 1.1 18.7 18.7 0 0 0 37.35 0 1.06 1.06 0 0 0-1.07-1.1h-8.35a1.05 1.05 0 0 0-1.02.96 8.29 8.29 0 0 1-8.24 7.32ZM16.37 12.51a8.25 8.25 0 0 1 7.24 1.3 1.07 1.07 0 0 0 1.4-.06l5.92-5.91a1.06 1.06 0 0 0-.03-1.54 18.7 18.7 0 1 0 0 28.34 1.06 1.06 0 0 0 .03-1.54l-5.9-5.9a1.04 1.04 0 0 0-1.35-.13 8.2 8.2 0 0 1-4.98 1.68 8.3 8.3 0 0 1-8.05-10.25 8.2 8.2 0 0 1 5.72-5.99Z"
        ></path>
      </svg>
    </div>
  </a>
);

function displayNameFor(tag: string): string {
  return DISPLAY_NAMES[tag] || `Visningsnamn saknas för ${tag}`;
}

function notUuid(tag: string) {
  let match = tag.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  return !match || match.length === 0;
}

const DISPLAY_NAMES: { [tag: string]: string } = {
  persons: "Personlista",
  calendar: "Kalender",
  polls: "Svarslistor",
  images: "Bilder",
  albums: "Album",
  links: "Länkar",
  linksadmin: "Administrera länkar",
};
