import { find, map } from "lodash";
import React, { useState } from "react";
import { LabelledInputBaseProps } from "./new-input/LabelledInputBaseProps";
import { ErrorFeedbackProps, withErrorFeedback } from "../validation/WithErrorFeedback";

export interface Option {
  id: string;
  label: string;
}

export interface SelectInputProps extends LabelledInputBaseProps, ErrorFeedbackProps {
  value?: Option;
  options?: Array<Option>;
  onChange: (value?: Option) => void;
  disabled?: boolean;
}

const Component: React.FC<SelectInputProps> = ({
  focus,
  value,
  label,
  options,
  onChange,
  className,
  onModified = () => undefined,
  errorBorderClass,
  disabled,
}) => {
  const [selection, setSelection] = useState(value);
  return (
    <>
      <label className={`${className} input`}>
        <select
          className={`${errorBorderClass} input__field form-control form-control-sm`}
          autoFocus={focus}
          value={find(options, (o) => o.id === selection?.id)?.id ?? "noselection"}
          onChange={(e) => {
            const option = find(options, (o) => o.id === e.currentTarget.value);
            setSelection(option);
            onChange(option);
            onModified();
            return false;
          }}
          disabled={disabled}
        >
          {!selection ? (
            <option value="noselection" disabled>
              Välj ett alternativ...
            </option>
          ) : null}
          {map(options, (o) => (
            <option key={o.id} value={o.id}>
              {o.label}
            </option>
          ))}
        </select>
        <span className="input__label">{label}</span>
      </label>
    </>
  );
};

export const LabelledSelect = withErrorFeedback(Component);
