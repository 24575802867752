import { ValidationError } from "./model/Validation.model";

export class ValidationErrors {
  errors: Array<ValidationError> = [];
  timeStamp = Date.now();
  hasErrors(): boolean {
    return this.errors.length > 0;
  }
  for(property: string) {
    const created = validationErrorsOf(this.errors.filter((error) => error.propertyPath === property));
    created.timeStamp = this.timeStamp;
    return created;
  }
  equals(other: ValidationErrors) {
    return JSON.stringify(this) === JSON.stringify(other);
  }
}

export function validationErrorsOf(errors: Array<ValidationError>) {
  const created = new ValidationErrors();
  created.errors = errors;
  return created;
}

export function validationErrorsEmpty() {
  return new ValidationErrors();
}
