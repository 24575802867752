import { Metadata } from "./Metadata.model";

export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export interface Media {
  uuid: string;
  url: string;
  fullSizeUrl: string | undefined;
  mediaType: MediaType;
  metaData: Metadata;
}

export interface MediaUpdateDto {
  metadata: Metadata;
}

export interface ImageOfTheDay {
  image: Media;
  albumName: string;
  albumId: string;
}
