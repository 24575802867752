import { find, map } from "lodash";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App.state";
import { useBackendApi } from "../../common/backend/backendApi";
import { Respondant, SavedPoll, UpdatePollResponseCommand, ValidPollData } from "../../common/backend/model/Poll.model";
import { FloatingActionButton } from "../../common/components/buttons/FloatingActionButton";
import {
  ChangePollAnswerInOffCanvas,
  CreatePollFormInOffCanvas,
  ModifyPollFormInOffCanvas,
  PollAnswerFormInOffCanvas,
} from "./PollFormInOffCanvas";
import { PollList } from "./poll-list/PollList";
import { useParams } from "react-router-dom";

interface InternalState {
  showCreatePollForm: boolean;
  showModifyPollForm: boolean;
  showPollAnswerForm: boolean;
  showChangeAnswerForm: boolean;
  selectedPoll?: SavedPoll;
}

function convertToValidPollData(savedPoll: SavedPoll): ValidPollData {
  return {
    ...savedPoll,
    respondants: map(savedPoll.responses, (p) => ({ ...p, uuid: p.personUUID } as Respondant)),
  };
}

export const PollsAdministrationPage: React.FC = () => {
  const controller = usePollsAdministrationPageController();
  const [urlPollOpened, setUrlPollOpened] = useState(false);
  let { uuid } = useParams();

  useEffect(() => {
    // This effect should only run when 'uuid' has been set and 'polls' is done loading.
    if (!urlPollOpened && uuid && controller.polls.value) {
      controller.openPollAnswerForm(uuid);
      setUrlPollOpened(true);
    }
  }, [uuid, urlPollOpened, controller, controller.polls.value]);

  if (controller.loggedInPerson.loading || controller.persons.loading || controller.polls.loading) {
    return null;
  }
  if (!controller.loggedInPerson.value) {
    return <div>Du är inte inloggad!</div>;
  }

  return (
    <main className="container p-2">
      <PollList polls={controller.polls} onOpenPoll={(pollId) => controller.openPollAnswerForm(pollId)} />

      <FloatingActionButton label="Lägg till" bottom={70} onClick={controller.openCreatePollForm} />

      <CreatePollFormInOffCanvas
        show={controller.showCreatePollForm}
        persons={controller.persons}
        onHide={controller.closeForms}
        onCreatePoll={controller.createPoll}
        onValidatePoll={controller.validatePoll}
        onClose={controller.closeForms}
      />

      {controller.selectedPoll ? (
        <>
          <PollAnswerFormInOffCanvas
            show={controller.selectedPoll && controller.showPollAnswerForm}
            loggedInPerson={controller.loggedInPerson.value}
            poll={controller.selectedPoll}
            onOpenPollEditor={controller.openPollEditor}
            onUpdatePollResponse={controller.answerPoll}
            onHide={controller.closeForms}
            onClose={controller.closeForms}
            onOpenChangeAnswerForm={controller.openChangeAnswerForm}
          />
          <ModifyPollFormInOffCanvas
            show={controller.showModifyPollForm}
            pollUUID={controller.selectedPoll.uuid}
            savedPoll={convertToValidPollData(controller.selectedPoll)}
            persons={controller.persons}
            onHide={controller.closeForms}
            onUpdatePoll={(uuid, pollData, sendNotification) =>
              controller.saveModifiedPoll(uuid, pollData, sendNotification)
            }
            onRemovePoll={controller.removePoll}
            onValidatePoll={controller.validatePoll}
            onClose={controller.closeForms}
          />
          <ChangePollAnswerInOffCanvas
            show={controller.showChangeAnswerForm}
            persons={controller.persons.value || []}
            poll={controller.selectedPoll}
            onChangePollAnswer={controller.changePollAnswer}
            onHide={controller.closeForms}
            onClose={controller.closeForms}
          />
        </>
      ) : null}
    </main>
  );
};

function usePollsAdministrationPageController() {
  const appState = useContext(AppContext).state;
  const backendApi = useBackendApi();

  useEffect(() => {
    backendApi.persons.loadLoggedInPerson();
    backendApi.persons.loadPersons();
    backendApi.polls.loadAllPolls();
  }, [backendApi.polls, backendApi.persons]);

  const [state, setState] = useState<InternalState>({
    showChangeAnswerForm: false,
    showPollAnswerForm: false,
    showCreatePollForm: false,
    showModifyPollForm: false,
  });

  return {
    loggedInPerson: appState.loggedInPerson,

    openPollEditor: (selectedPollId: string) => {
      const selectedPoll: SavedPoll | undefined = find(appState.allSavedPolls.value, (p) => p.uuid === selectedPollId);
      if (selectedPoll) {
        setState((prev) => ({
          ...prev,
          showModifyPollForm: true,
          showPollAnswerForm: false,
          showCreatePollForm: false,
          selectedPoll,
        }));
      }
    },
    showModifyPollForm: state.showModifyPollForm,
    saveModifiedPoll: async (uuid: string, poll: ValidPollData, sendNotification: boolean) => {
      await backendApi.polls.update(uuid, poll, sendNotification);
      setState((prev) => ({ ...prev, showModifyPollForm: false }));
    },

    showCreatePollForm: state.showCreatePollForm,
    openCreatePollForm: () =>
      setState((prev) => ({
        ...prev,
        showCreatePollForm: true,
        showModifyPollForm: false,
        showPollAnswerForm: false,
      })),
    createPoll: async (poll: ValidPollData) => {
      await backendApi.polls.createPoll(poll);
      setState((prev) => ({ ...prev, showCreatePollForm: false }));
    },

    selectedPoll: state.selectedPoll,
    showPollAnswerForm: state.showPollAnswerForm,
    openPollAnswerForm: (selectedPollId: string) => {
      const selectedPoll: SavedPoll | undefined = find(appState.allSavedPolls.value, (p) => p.uuid === selectedPollId);
      setState((prev) => ({
        ...prev,
        selectedPoll,
        showPollAnswerForm: true,
        showCreatePollForm: false,
      }));
    },
    showChangeAnswerForm: state.showChangeAnswerForm,
    openChangeAnswerForm: () => {
      setState((prev) => ({
        ...prev,
        showChangeAnswerForm: true,
        showPollAnswerForm: false,
        showCreatePollForm: false,
      }));
    },
    changePollAnswer: async (pollId: string, userId: string, updatePollResponseCommand: UpdatePollResponseCommand) => {
      await backendApi.polls.changePollAnswer(pollId, userId, updatePollResponseCommand);
      setState((prev) => ({
        ...prev,
        showModifyPollForm: false,
        showPollAnswerForm: true,
        showCreatePollForm: false,
        showChangeAnswerForm: false,
      }));
    },
    removePoll: async (uuid: string) => {
      await backendApi.polls.deletePoll(uuid);
      setState((prev) => ({ ...prev, showModifyPollForm: false }));
    },
    validatePoll: async (poll: Partial<ValidPollData>) => {
      return await backendApi.polls.validatePoll(poll);
    },

    answerPoll: async (pollId: string, updatePollResponseCommand: UpdatePollResponseCommand) => {
      await backendApi.polls.updatePollResponse(pollId, updatePollResponseCommand);
      setState((prev) => ({
        ...prev,
        showModifyPollForm: false,
        showPollAnswerForm: false,
        showCreatePollForm: false,
        showChangeAnswerForm: false,
      }));
    },

    persons: appState.persons,
    polls: appState.allSavedPolls,

    closeForms: () =>
      setState((prev) => ({
        ...prev,
        showModifyPollForm: false,
        showPollAnswerForm: false,
        showCreatePollForm: false,
        showChangeAnswerForm: false,
      })),
  };
}
