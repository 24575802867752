import { forEach, isEmpty, map } from "lodash";
import { useContext } from "react";
import { v4 as uuid } from "uuid";
import { AppContext } from "../../App.state";
import { useNotifications } from "../../pages/rootpage/Notifications";
import { BasicLinkData, SavedLink } from "./model/Link.model";
import { Person } from "./model/Person.model";
import { SavedPoll, UpdatePollResponseCommand, ValidPollData } from "./model/Poll.model";
import { Album, AlbumData } from "./model/Album.model";
import { CalendarEvent } from "../../pages/calendar/CalenderEvent.model";
import { ValidationErrors, validationErrorsEmpty, validationErrorsOf } from "./ValidationErrors";
import { ImageOfTheDay, Media, MediaType, MediaUpdateDto } from "./model/Media.model";
import { Stock } from "./model/Stock.model";

async function authenticatedFetch(url: string, options?: RequestInit): Promise<Response> {
  let r = await fetch(url, options);
  if (!r.ok && r.status === 401) {
    console.log("Sent request got 401: " + url);
    const originalPageUrl = encodeURIComponent(window.location.href);
    window.open(`/oauth2/authorization/azure?app_uri=${originalPageUrl}`, "_self");
  }
  return r;
}

export function useBackendApi() {
  async function asAsyncActivity<T>(name: string, activity: () => Promise<T>): Promise<T> {
    const activityId = uuid();
    try {
      dispatch({
        type: "StartOngoingActivity",
        activity: { activityId, name },
      });
      return await activity();
    } finally {
      dispatch({ type: "FinishOngoingActivity", activityId });
    }
  }

  const { state, dispatch } = useContext(AppContext);
  const notifications = useNotifications();

  function addErrorNotification(title: string, error: unknown) {
    let e = error as BackendApiError;
    if (e.response.status !== 401) {
      notifications.add({
        title: title,
        type: "ERROR",
        details: e.message,
      });
    }
  }

  function addSuccessNotification(title: string) {
    notifications.add({
      title: title,
      autoRemove: true,
      type: "SUCCESS",
    });
  }

  const backend = {
    persons: {
      loadPersons: async (force: boolean = false) => {
        if ((!state.persons.value || force) && !state.persons.loading) {
          await asAsyncActivity("Laddar personer", async () => {
            try {
              dispatch({ type: "SetPersons", persons: { loading: true } });
              const value = await backendApi.persons.getAll();
              dispatch({
                type: "SetPersons",
                persons: { loading: false, value },
              });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av personer", e);
            }
          });
        }
      },
      createPerson: async (person: Person, file: File) => {
        console.log("Creating person ", person);
        await asAsyncActivity("Skapar person", async () => {
          try {
            await backendApi.persons.createPerson(person, file);
            addSuccessNotification("Personen har skapats");
            await backend.persons.loadPersons(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel när personen skulle skapas", e);
          }
        });
      },
      updatePerson: async (person: Person, file: File) => {
        console.log("Updating person ", person);
        await asAsyncActivity("Uppdaterar person", async () => {
          try {
            await backendApi.persons.updatePerson(person, file);
            addSuccessNotification("Personen har uppdaterats");
            await backend.persons.loadPersons(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel när personen skulle uppdateras", e);
          }
        });
      },
      deletePerson: async (uuid: string) => {
        await asAsyncActivity("Tar bort person", async () => {
          try {
            await backendApi.persons.deletePerson(uuid);
            addSuccessNotification("Personen har tagits bort");
            await backend.persons.loadPersons(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel när personen skulle tas bort", e);
          }
        });
      },
      validatePerson: async (person: Partial<Person>) => {
        return await asAsyncActivity("Validerar album", async () => {
          try {
            return await backendApi.persons.validatePerson(person);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid validering av person", e);
            return validationErrorsEmpty();
          }
        });
      },
      loadLoggedInPerson: async (force: boolean = false) => {
        if ((!state.loggedInPerson.value || force) && !state.loggedInPerson.loading) {
          await asAsyncActivity("Laddar inloggad person", async () => {
            try {
              dispatch({
                type: "SetLoggedInPerson",
                person: { loading: true },
              });
              const value = await backendApi.persons.getLoggedinPerson();
              dispatch({
                type: "SetLoggedInPerson",
                person: { loading: false, value },
              });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av inloggad person", e);
            }
          });
        }
      },
    },
    polls: {
      loadAllPolls: async (force: boolean = false) => {
        if ((!state.allSavedPolls.value || force) && !state.allSavedPolls.loading) {
          await asAsyncActivity("Laddar frågor", async () => {
            try {
              dispatch({ type: "SetAllSavedPolls", polls: { loading: true } });
              const value = await backendApi.polls.getAll();
              dispatch({
                type: "SetAllSavedPolls",
                polls: { loading: false, value },
              });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av svarslistor", e);
            }
          });
        }
      },
      loadActivePolls: async (force: boolean = false) => {
        if ((!state.activePolls.value || force) && !state.activePolls.loading) {
          await asAsyncActivity("Laddar aktiva frågor", async () => {
            try {
              dispatch({ type: "SetActivePolls", polls: { loading: true } });
              const value = await backendApi.polls.getActive();
              dispatch({
                type: "SetActivePolls",
                polls: { loading: false, value },
              });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av aktiva frågor", e);
            }
          });
        }
      },
      createPoll: async (pollData: ValidPollData) => {
        await asAsyncActivity("Skapar fråga", async () => {
          try {
            await backendApi.polls.createPoll(pollData);
            addSuccessNotification("Frågan har skapats");
            await backend.polls.loadAllPolls(true);
            await backend.polls.loadActivePolls(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid skapande av fråga", e);
          }
        });
      },
      update: async (uuid: string, pollData: ValidPollData, sendNotification: boolean) => {
        await asAsyncActivity("Uppdaterar fråga", async () => {
          try {
            await backendApi.polls.update(uuid, pollData, sendNotification);
            addSuccessNotification("Frågan har uppdaterats");
            await backend.polls.loadAllPolls(true);
            await backend.polls.loadActivePolls(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid uppdatering av fråga", e);
          }
        });
      },
      updatePollResponse: async (uuid: string, updatePollResponseCommand: UpdatePollResponseCommand) => {
        await asAsyncActivity("Sparar svar på fråga", async () => {
          try {
            await backendApi.polls.updatePollResponse(uuid, updatePollResponseCommand);
            addSuccessNotification("Svaret har sparats");
            await backend.polls.loadAllPolls(true);
            await backend.polls.loadActivePolls(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid sparande av fråga", e);
          }
        });
      },
      changePollAnswer: async (
        pollId: string,
        userId: string,
        updatePollResponseCommand: UpdatePollResponseCommand
      ) => {
        await asAsyncActivity("Admin uppdaterar svar på fråga", async () => {
          try {
            await backendApi.polls.changePollAnswer(pollId, userId, updatePollResponseCommand);
            addSuccessNotification("Svaret har uppdaterats");
            await backend.polls.loadAllPolls(true);
            await backend.polls.loadActivePolls(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid uppdatering av fråga", e);
          }
        });
      },
      deletePoll: async (pollUuid: string) => {
        await asAsyncActivity("Tar bort fråga", async () => {
          try {
            await backendApi.polls.deletePoll(pollUuid);
            addSuccessNotification("Frågan har tagits bort");
            await backend.polls.loadAllPolls(true);
            await backend.polls.loadActivePolls(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid borttagning av fråga", e);
          }
        });
      },
      validatePoll: async (pollData: Partial<ValidPollData>) => {
        return await asAsyncActivity("Validerar fråga", async () => {
          try {
            return await backendApi.polls.validatePoll(pollData);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid validering av fråga", e);
            return validationErrorsEmpty();
          }
        });
      },
    },
    links: {
      loadLinks: async (force: boolean = false) => {
        if ((!state.links.value || force) && !state.links.loading) {
          await asAsyncActivity("Laddar länkar", async () => {
            try {
              dispatch({ type: "SetLinks", links: { loading: true } });
              const value = await backendApi.links.getLinks();
              dispatch({ type: "SetLinks", links: { loading: false, value } });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av länkar", e);
            }
          });
        }
      },
      loadNewestLinks: async (force: boolean = false) => {
        if ((!state.newestLinks.value || force) && !state.newestLinks.loading) {
          await asAsyncActivity("Laddar senaste länkarna", async () => {
            try {
              dispatch({ type: "SetNewestLinks", links: { loading: true } });
              const value = await backendApi.links.getNewestLinks();
              dispatch({ type: "SetNewestLinks", links: { loading: false, value } });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av länkar för landningssidan", e);
            }
          });
        }
      },
      createLink: async (linkData: BasicLinkData) => {
        await asAsyncActivity("Skapar länk", async () => {
          try {
            await backendApi.links.createLink(linkData);
            addSuccessNotification("Länken har sparats");
            await backend.links.loadLinks(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel när länken skulle skapas", e);
          }
        });
      },
      createFileLink: async (linkData: BasicLinkData, file: File) => {
        await asAsyncActivity("Skapar länk", async () => {
          try {
            await backendApi.links.createFileLink(linkData, file);
            addSuccessNotification("Länken har sparats");
            await backend.links.loadLinks(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel när fillänken skulle skapas", e);
          }
        });
      },
      updateLink: async (uuid: string, linkData: BasicLinkData) => {
        await asAsyncActivity("Uppdaterar länk", async () => {
          try {
            await backendApi.links.updateLink(uuid, linkData);
            addSuccessNotification("Länken har uppdaterats");
            await backend.links.loadLinks(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel när länken skulle uppdateras", e);
          }
        });
      },
      deleteLink: async (uuid: string) => {
        await asAsyncActivity("Tar bort länk", async () => {
          try {
            await backendApi.links.deleteLink(uuid);
            addSuccessNotification("Länken har tagits bort");
            await backend.links.loadLinks(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel när länken skulle tas bort", e);
          }
        });
      },
      validateUrlLink: async (linkData: BasicLinkData) => {
        return await asAsyncActivity("Validerar länk", async () => {
          try {
            return await backendApi.links.validateUrlLink(linkData);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid validering av länk", e);
            return validationErrorsEmpty();
          }
        });
      },
      validateFileLink: async (linkData: BasicLinkData) => {
        return await asAsyncActivity("Validerar länk", async () => {
          try {
            return await backendApi.links.validateFileLink(linkData);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid validering av länk", e);
            return validationErrorsEmpty();
          }
        });
      },
    },
    gallery: {
      loadAlbums: async (force: boolean = false) => {
        if ((!state.albums.value || force) && !state.albums.loading) {
          await asAsyncActivity("Laddar album", async () => {
            try {
              dispatch({ type: "SetAlbums", albums: { loading: true } });
              const value = await backendApi.gallery.getAlbums();
              dispatch({
                type: "SetAlbums",
                albums: { loading: false, value },
              });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av album", e);
            }
          });
        }
      },
      createAlbum: async (albumData: AlbumData, files: File[]) => {
        await asAsyncActivity("Skapar album", async () => {
          try {
            const createdAlbum = await backendApi.gallery.createAlbum(albumData);
            for (const file of files) {
              await backendApi.gallery.uploadImage(file, createdAlbum.uuid);
            }
            notifications.add({
              title: "Albumet har skapats, thumbnails kommer att genereras i bakgrunden då det tar tid.",
              autoRemove: false,
              type: "SUCCESS",
            });
            await backend.gallery.loadAlbums(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid skapande av album", e);
          }
        });
      },
      updateAlbum: async (uuid: string, albumData: AlbumData, files: File[]) => {
        await asAsyncActivity("Uppdaterar album", async () => {
          try {
            await backendApi.gallery.updateAlbum(uuid, albumData);
            for (const file of files) {
              await backendApi.gallery.uploadImage(file, uuid);
            }
            addSuccessNotification("Albumet har uppdaterats");
            await backend.gallery.loadAlbums(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid uppdatering av album", e);
          }
        });
      },
      deleteAlbum: async (albumUuid: string) => {
        await asAsyncActivity("Tar bort album", async () => {
          try {
            await backendApi.gallery.deleteAlbum(albumUuid);
            addSuccessNotification("Albumet har tagits bort");
            await backend.gallery.loadAlbums(true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid borttagning av album", e);
          }
        });
      },
      validateAlbum: async (albumData: Partial<AlbumData>) => {
        return await asAsyncActivity("Validerar album", async () => {
          try {
            return await backendApi.gallery.validateAlbum(albumData);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid validering av album", e);
            return validationErrorsEmpty();
          }
        });
      },
      loadImages: async (albumUuid: string, force: boolean = false) => {
        if ((!state.medias.value || force) && !state.medias.loading) {
          await asAsyncActivity("Laddar bilder", async () => {
            try {
              dispatch({ type: "SetMedias", medias: { loading: true } });
              const value = await backendApi.gallery.getMedias(albumUuid);
              dispatch({
                type: "SetMedias",
                medias: { loading: false, value },
              });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av bilder", e);
            }
          });
        }
      },
      updateMedia: async (uuid: string, dto: MediaUpdateDto, mediaType: MediaType, albumId: string) => {
        await asAsyncActivity("Uppdaterar objekt", async () => {
          try {
            await backendApi.gallery.updateMedia(uuid, dto, mediaType);
            addSuccessNotification("Objektet har uppdaterats");
            await backend.gallery.loadImages(albumId, true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid uppdatering av objekt", e);
          }
        });
      },
      deleteMedia: async (uuid: string, mediaType: MediaType, albumId: string) => {
        await asAsyncActivity("Tar bort objekt", async () => {
          try {
            await backendApi.gallery.deleteMedia(uuid, mediaType);
            addSuccessNotification("Objektet har tagits bort");
            await backend.gallery.loadImages(albumId, true);
          } catch (e) {
            console.error(e);
            addErrorNotification("Något gick fel vid borttagning av objektet", e);
          }
        });
      },
      loadImageOfTheDay: async (force: boolean = false) => {
        if ((!state.imageOfTheDay.value || force) && !state.imageOfTheDay.loading) {
          await asAsyncActivity("Laddar dagens bild", async () => {
            try {
              dispatch({ type: "SetImageOfTheDay", image: { loading: true } });
              const value = await backendApi.gallery.getImageOftheDay();
              dispatch({
                type: "SetImageOfTheDay",
                image: { loading: false, value },
              });
            } catch (e) {
              console.error(e);
            }
          });
        }
      },
    },
    calendarEvents: {
      add: async (calendarEventRequest: CalendarEvent) => {
        try {
          await doRequest("POST", calendarEventRequest, "/api/v1/calendar/events");
          addSuccessNotification("Kalenderhändelse skapad");
        } catch (e) {
          const errMsg = await (e as BackendApiError).detailedMessage();
          return warning("Kunde inte skapa ny händelse", errMsg);
        }
      },

      list: async (force = false) => {
        if ((!state.calendarEvents.value || force) && !state.calendarEvents.loading) {
          try {
            dispatch({ type: "SetCalendarEvents", data: { loading: true } });
            let events: CalendarEvent[] = await doGetRequest("/api/v1/calendar/events");
            dispatch({
              type: "SetCalendarEvents",
              data: { loading: false, value: events },
            });
          } catch (err) {
            const errMsg = await (err as BackendApiError).detailedMessage();
            return warning("Något gick fel vid hämtning av kalenderhändelser", errMsg);
          }
        }
      },
      listOld: async (force = false) => {
        if ((!state.oldCalendarEvents.value || force) && !state.oldCalendarEvents.loading) {
          try {
            dispatch({ type: "SetOldCalendarEvents", data: { loading: true } });
            let events: CalendarEvent[] = await doGetRequest("/api/v1/calendar/events/inactive");
            dispatch({
              type: "SetOldCalendarEvents",
              data: { loading: false, value: events },
            });
          } catch (err) {
            const errMsg = await (err as BackendApiError).detailedMessage();
            return warning("Något gick fel vid hämtning av gamla kalenderhändelser", errMsg);
          }
        }
      },

      update: async (calendarEventRequest: CalendarEvent) => {
        try {
          await doRequest("PUT", calendarEventRequest, `/api/v1/calendar/events/${calendarEventRequest.uuid}`);
          addSuccessNotification("Kalenderhändelse uppdaterad");
        } catch (err) {
          const errMsg = await (err as BackendApiError).detailedMessage();
          return warning("Kunde inte uppdatera händelse", errMsg);
        }
      },

      delete: async (uuid: string) => {
        try {
          await doDeleteRequest(`/api/v1/calendar/events/${uuid}`);
          return addSuccessNotification("Kalenderhändelse borttagen");
        } catch (err) {
          const errMsg = await (err as BackendApiError).detailedMessage();
          return warning("Kan inte ta bort kalenderhändelsen", errMsg);
        }
      },
    },
    stock: {
      loadStock: async (force: boolean = false) => {
        if ((!state.stock.value || force) && !state.stock.loading) {
          await asAsyncActivity("Laddar aktiedata", async () => {
            try {
              dispatch({ type: "SetStockData", stock: { loading: true } });
              const value = await backendApi.stock.getStock();
              dispatch({
                type: "SetStockData",
                stock: { loading: false, value },
              });
            } catch (e) {
              console.error(e);
              addErrorNotification("Något gick fel vid hämtning av aktiedata", e);
            }
          });
        }
      },
    },
  };

  function warning(errorMessage: string, errorDetails: string) {
    notifications.add({
      title: errorMessage,
      type: "ERROR",
      details: errorDetails,
    });
    return Promise.reject(errorMessage);
  }

  return backend;
}

export class BackendApiError {
  constructor(public readonly response: Response) {}

  get message(): string {
    return `${this.response.status} ${this.response.statusText}`;
  }

  async detailedMessage(): Promise<string> {
    let problemDetails = this.message;
    try {
      const jsonText = await this.response.text();
      if (!isEmpty(jsonText)) {
        problemDetails += ": " + JSON.parse(jsonText).detail;
      }
    } catch (e) {
      console.warn("Unable to parse response.text");
    }

    return problemDetails;
  }
}

const backendApi = {
  persons: {
    async getLoggedinPerson(): Promise<Person> {
      const r = await authenticatedFetch("/api/v1/persons/logged-in-person");
      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return await r.json();
    },
    async getAll(): Promise<Array<Person>> {
      const r = await authenticatedFetch("/api/v1/persons");

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      const personWithMetadata: Array<any> = await r.json();
      return map(personWithMetadata, (pwm) => ({ ...pwm.person, allowedToEdit: pwm.isAllowedToEdit } as Person));
    },
    async createPerson(person: Person, file: File): Promise<void> {
      console.log("API: Creating person with profile image", person);
      const formData = new FormData();
      formData.append("person", new Blob([JSON.stringify(person)], { type: "application/json" }));
      formData.append("profileImage", file);

      const response = await authenticatedFetch(`/api/v1/persons`, {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
        },
        body: formData,
      });
      if (!response.ok) {
        throw new BackendApiError(response);
      }
    },
    async updatePerson(person: Person, file: File): Promise<void> {
      console.log("Updating person ", person);
      const formData = new FormData();
      formData.append("person", new Blob([JSON.stringify(person)], { type: "application/json" }));
      formData.append("profileImage", file);

      const response = await authenticatedFetch(`/api/v1/persons`, {
        method: "PUT",
        headers: {
          ...getCsrfHeader(),
        },
        body: formData,
      });
      if (!response.ok) {
        throw new BackendApiError(response);
      }
    },
    async deletePerson(uuid: string): Promise<void> {
      const l = await authenticatedFetch(`/api/v1/persons/${uuid}`, {
        method: "DELETE",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
      });
      if (!l.ok) {
        throw new BackendApiError(l);
      }
    },
    async validatePerson(person: Partial<Person>): Promise<ValidationErrors> {
      console.log("backendApi.validate", person);
      const r = await authenticatedFetch(`/api/v1/persons/validate`, {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify(person),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return validationErrorsOf(await r.json());
    },
  },

  polls: {
    async getAll(): Promise<Array<SavedPoll>> {
      const r = await authenticatedFetch("/api/v1/polls");

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return await r.json();
    },

    async getActive(): Promise<Array<SavedPoll>> {
      const r = await authenticatedFetch("/api/v1/polls/active-polls");

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return await r.json();
    },

    async createPoll(pollData: ValidPollData): Promise<void> {
      const r = await authenticatedFetch("/api/v1/polls", {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...pollData,
          respondants: map(pollData.respondants, (r) => r.uuid),
        }),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },

    async update(uuid: string, pollData: ValidPollData, sendNotification: boolean): Promise<void> {
      console.log("Updating poll ", pollData + " with sendNotification: " + sendNotification);
      const r = await authenticatedFetch(`/api/v1/polls/${uuid}?sendNotification=${sendNotification}`, {
        method: "PUT",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...pollData,
          respondants: map(pollData.respondants, (r) => r.uuid),
        }),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },

    async updatePollResponse(uuid: string, updatePollResponseCommand: UpdatePollResponseCommand): Promise<void> {
      const r = await authenticatedFetch(`/api/v1/polls/${uuid}/update-response`, {
        method: "PUT",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify(updatePollResponseCommand),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
    async changePollAnswer(
      pollId: string,
      userId: string,
      updatePollResponseCommand: UpdatePollResponseCommand
    ): Promise<void> {
      const r = await authenticatedFetch(`/api/v1/polls/${pollId}/update-response/userId/${userId}`, {
        method: "PUT",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify(updatePollResponseCommand),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
    async validatePoll(pollData: Partial<ValidPollData>): Promise<ValidationErrors> {
      console.log("backendApi.validate", pollData);
      const r = await authenticatedFetch(`/api/v1/polls/validate`, {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...pollData,
          respondants: map(pollData.respondants, (r) => r.uuid),
        }),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return validationErrorsOf(await r.json());
    },

    async deletePoll(pollUuid: string): Promise<void> {
      const r = await authenticatedFetch(`/api/v1/polls/${pollUuid}`, {
        method: "DELETE",
        headers: {
          ...getCsrfHeader(),
        },
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
  },

  links: {
    async getLinks(): Promise<Array<SavedLink>> {
      const r = await authenticatedFetch("/api/v1/links");
      if (!r.ok) {
        throw new BackendApiError(r);
      }
      return await r.json();
    },

    async getNewestLinks(): Promise<Array<SavedLink>> {
      const r = await authenticatedFetch("/api/v1/links/newest");
      if (!r.ok) {
        throw new BackendApiError(r);
      }
      return await r.json();
    },

    async createLink(linkData: BasicLinkData): Promise<void> {
      const l = await authenticatedFetch("/api/v1/links", {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify(linkData),
      });
      if (!l.ok) {
        throw new BackendApiError(l);
      }
    },
    async createFileLink(linkData: BasicLinkData, file: File): Promise<void> {
      const r = await uploadFiles("/api/v1/links", linkData, [file]);
      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
    async updateLink(uuid: string, linkData: BasicLinkData): Promise<void> {
      const l = await authenticatedFetch(`/api/v1/links/${uuid}`, {
        method: "PUT",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify(linkData),
      });
      if (!l.ok) {
        throw new BackendApiError(l);
      }
    },
    async deleteLink(uuid: string): Promise<void> {
      const l = await authenticatedFetch(`/api/v1/links/${uuid}`, {
        method: "DELETE",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
      });
      if (!l.ok) {
        throw new BackendApiError(l);
      }
    },
    async validateUrlLink(linkData: BasicLinkData): Promise<ValidationErrors> {
      console.log("backendApi.validate", linkData);
      const r = await authenticatedFetch(`/api/v1/links/validateUrlLink`, {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify(linkData),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return validationErrorsOf(await r.json());
    },
    async validateFileLink(linkData: BasicLinkData): Promise<ValidationErrors> {
      console.log("backendApi.validate", linkData);
      const r = await authenticatedFetch(`/api/v1/links/validateFileLink`, {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify(linkData),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return validationErrorsOf(await r.json());
    },
  },

  gallery: {
    async getAlbums(): Promise<Array<Album>> {
      const r = await authenticatedFetch("/api/v1/albums");

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return await r.json();
    },
    async createAlbum(albumData: AlbumData): Promise<Album> {
      const r = await authenticatedFetch("/api/v1/albums", {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...albumData,
        }),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
      return r.json();
    },
    async updateAlbum(uuid: string, albumData: AlbumData): Promise<void> {
      const r = await authenticatedFetch(`/api/v1/albums/${uuid}`, {
        method: "PUT",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...albumData,
        }),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
    async deleteAlbum(albumUuid: string): Promise<void> {
      const r = await authenticatedFetch(`/api/v1/albums/${albumUuid}`, {
        method: "DELETE",
        headers: {
          ...getCsrfHeader(),
        },
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
    async uploadImage(file: File, albumUuid: string): Promise<void> {
      const r = await uploadFiles("/api/v1/albums/" + albumUuid + "/medias", {}, [file]);

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
    async getMedias(albumUuid: string): Promise<Array<Media>> {
      const r = await authenticatedFetch("/api/v1/albums/" + albumUuid + "/medias");
      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return await r.json();
    },
    async validateAlbum(albumData: Partial<AlbumData>): Promise<ValidationErrors> {
      console.log("backendApi.validate", albumData);
      const r = await authenticatedFetch(`/api/v1/albums/validateAlbum`, {
        method: "POST",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify(albumData),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return validationErrorsOf(await r.json());
    },
    async updateMedia(uuid: string, dto: MediaUpdateDto, mediaType: MediaType) {
      const r = await authenticatedFetch(`/api/v1/albums/medias/${uuid}/${mediaType}`, {
        method: "PUT",
        headers: {
          ...getCsrfHeader(),
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...dto,
        }),
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
    async deleteMedia(uuid: string, mediaType: MediaType) {
      const r = await authenticatedFetch(`/api/v1/albums/medias/${uuid}/${mediaType}`, {
        method: "DELETE",
        headers: {
          ...getCsrfHeader(),
        },
      });

      if (!r.ok) {
        throw new BackendApiError(r);
      }
    },
    async getImageOftheDay(): Promise<ImageOfTheDay> {
      const r = await authenticatedFetch("/api/v1/albums/imageOfDay");

      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return await r.json();
    },
  },

  stock: {
    async getStock(): Promise<Stock> {
      const r = await authenticatedFetch("/api/v1/stock");
      if (!r.ok) {
        throw new BackendApiError(r);
      }

      return await r.json();
    },
  },
};

/**
 * Get the X-XSRF-TOKEN header with value extracted from the
 * XSRF-TOKEN cookie to be added to all mutating requests.
 * This is to meet the requirements of the CSRF protection in Spring Security
 * (see https://docs.spring.io/spring-security/site/docs/5.0.x/reference/html/csrf.html)
 */
function getCsrfHeader(): { "x-xsrf-token": string } {
  const csrfToken = document.cookie.replace(/(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$|^.*$/, "$1");

  return {
    "x-xsrf-token": csrfToken,
  };
}

async function doGetRequest<T>(uri: string): Promise<T> {
  const response = await authenticatedFetch(uri);
  if (!response.ok) {
    throw new BackendApiError(response);
  }
  return await response.json();
}

async function doRequest(method: string, postData: any, uri: string) {
  const resp = await authenticatedFetch(uri, {
    method: method,
    headers: {
      ...getCsrfHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });

  if (!resp.ok) {
    throw new BackendApiError(resp);
  }
}

async function doDeleteRequest(uri: string) {
  const resp = await authenticatedFetch(uri, {
    method: "DELETE",
    headers: {
      ...getCsrfHeader(),
    },
  });

  if (!resp.ok) {
    throw new BackendApiError(resp);
  }
}

/**
 * Example usage:
 * ```ts
 *    uploadFiles('/api/v1/polls/upload', {banan: 'kaka'}, [new File(["En fejkad fil"], "ubbabubba")])
 * ```
 * (where files could be an array of files fetched with the FileSelect component.
 * which can be received in a springboot server with
 * ```java
 *    @PostMapping(value = "/upload", consumes = MediaType.MULTIPART_FORM_DATA_VALUE)
 *    public void upload(MultipartRequest uploadRequest) {
 *      uploadRequest.getFileMap().forEach((key,value) -> {
 *        try {
 *          System.out.println("key: %s".formatted(key));
 *          System.out.println("value: %s".formatted(new String(value.getBytes(), StandardCharsets.UTF_8)));
 *        } catch (IOException e) {
 *          throw new RuntimeException(e);
 *        }
 *      });
 *    }
 * ```
 * which would be logged in the springboot server accordingly:
 * ```shell
 *    key: metadata
 *    value: {"banan":"kaka"}
 *    key: ubbabubba
 *    value: En fejkad fil
 * ```
 *
 * @param url the URL, e.g "/api/v1/polls/upload"
 * @param metadata an object; will be named metadata in the multipart
 * @param files possible array of files
 */
export async function uploadFiles<T>(url: string, metadata: T, files: Array<File>): Promise<Response> {
  const formData: FormData = new FormData();
  formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json" }), "metadata");
  forEach(files, (file) => formData.append(file.name, file, file.name));
  return fetch(url, {
    method: "POST",
    headers: {
      ...getCsrfHeader(),
    },
    body: formData,
  });
}
