import { filter, find, groupBy, isEmpty, map } from "lodash";
import React, { useState } from "react";
import { Person, Role } from "../../common/backend/model/Person.model";
import { PollResponse, SavedPoll, UpdatePollResponseCommand } from "../../common/backend/model/Poll.model";
import { ActionButton } from "../../common/components/buttons/ActionButton";
import { LabelledSelect } from "../../common/components/labelled-inputs/LabelledSelect";
import { LabelledTextArea } from "../../common/components/labelled-inputs/LabelledTextArea";
import { StackLayout } from "../../common/components/layout/StackLayout";
import { MultilineText } from "../../common/components/presentation/MultilineText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export interface PollAnswerFormProps {
  loggedInPerson: Person;
  poll: SavedPoll;
  onUpdatePollResponse: (pollId: string, updatePollResponseCommand: UpdatePollResponseCommand) => void;
  onClose: () => void;
  onOpenPollEditor: (pollId: string) => void;
  onOpenChangeAnswerForm: () => void;
}

export const PollAnswerForm: React.FC<PollAnswerFormProps> = (props: PollAnswerFormProps) => {
  const { loggedInPerson, poll, onUpdatePollResponse, onClose, onOpenPollEditor } = props;

  const loggedInPersonsPollAnswer = find(poll.responses, (r) => r.personUUID === loggedInPerson.uuid);

  const [state, setState] = useState({
    selectedAlternative: loggedInPersonsPollAnswer?.response,
    comment: loggedInPersonsPollAnswer?.responseComment,
    saving: false,
  });
  const options = map(poll.responseOptions, (a) => ({
    id: a,
    label: a,
  }));

  return (
    <>
      <div className="p-2">
        <div className="d-flex gap-1 align-items-start border-bottom border-1 border-secondary">
          <h4 className="flex-grow-1 ">{poll.heading}</h4>
          {poll.editAllowed ? (
            <ActionButton variant="outline-primary" size="sm" onClick={() => onOpenPollEditor(poll.uuid)}>
              Ändra
            </ActionButton>
          ) : null}
        </div>
        <br />
        <MultilineText>{poll.detailText}</MultilineText>
        <div className="d-flex gap-2 flex-column mt-3">
          {loggedInPersonsPollAnswer ? ( // Only show this section if the logged in person is among the respondants (i.e. is among the answers)
            <>
              <LabelledSelect
                label="Svar"
                focus={true}
                value={find(options, (o) => o.id === loggedInPersonsPollAnswer?.response)}
                options={options}
                onChange={(o) => setState((prev) => ({ ...prev, selectedAlternative: o?.id }))}
              />
              <LabelledTextArea
                label="Kommentar"
                value={state.comment ?? ""}
                onChange={(v) =>
                  setState((prev) => ({
                    ...prev,
                    comment: v !== "" ? v : undefined,
                  }))
                }
              />
              {poll.active ? (
                <div className="d-flex flex-wrap gap-1 justify-content-end">
                  <ActionButton variant="secondary" size="sm" onClick={() => onClose()}>
                    Avbryt
                  </ActionButton>
                  <ActionButton
                    variant="primary"
                    size="sm"
                    disabled={
                      state.saving ||
                      (!state.selectedAlternative && loggedInPersonsPollAnswer.responseComment === state.comment)
                    }
                    onClick={() => {
                      setState((prev) => ({ ...prev, saving: true }));
                      onUpdatePollResponse(poll.uuid, {
                        selectedAlternative: state.selectedAlternative,
                        comment: state.comment,
                      });
                    }}
                  >
                    Spara svar{" "}
                  </ActionButton>
                </div>
              ) : null}
            </>
          ) : null}

          <div className="mt-3">
            <StackLayout gap={3}>
              <PersonsAnswers alternatives={poll.responseOptions} answers={poll.responses} />
              {loggedInPerson.role === Role.ADMINISTRATOR && poll.active && (
                <ActionButton
                  label="Ändra svar"
                  variant="outline-secondary"
                  size="sm"
                  onClick={props.onOpenChangeAnswerForm}
                />
              )}
            </StackLayout>
          </div>
        </div>
      </div>
    </>
  );
};

const PersonsAnswers: React.FC<{
  alternatives: Array<string>;
  answers: Array<PollResponse>;
}> = ({ alternatives, answers }) => {
  const NO_ANSWER_KEY = "No-Answer-Given-Yet";
  const groupedByAnswer = groupBy(answers, (a) => a.response ?? NO_ANSWER_KEY);
  const comments = filter(answers, (a) => !!a.responseComment);
  const cardsForEachAlternative = map(alternatives, (alternative) => (
    <Group key={alternative} alternative={alternative} group={groupedByAnswer[alternative]} />
  ));

  return (
    <>
      <h5 className="border-bottom border-1 border-secondary">Svar</h5>
      <StackLayout gap={4}>
        <StackLayout gap={2}>
          {cardsForEachAlternative}
          <Group alternative="Har ej svarat" group={groupedByAnswer[NO_ANSWER_KEY]} />
        </StackLayout>
        {!isEmpty(comments) ? (
          <div>
            <h5 className="border-bottom border-1 border-secondary">Kommentarer</h5>
            <StackLayout gap={1}>
              {comments.sort(commentComparator).map((a) => (
                <div key={a.personUUID}>
                  <div>
                    <div>
                      <strong>
                        {a.firstName} {a.lastName}
                      </strong>
                      <strong style={{ fontSize: "0.9rem" }}>{formatDate(a.commentTimeStamp)}</strong>
                    </div>
                    <div>
                      <i>{a.responseComment}</i>
                    </div>
                  </div>
                </div>
              ))}
            </StackLayout>
          </div>
        ) : null}
      </StackLayout>
    </>
  );
};

const Group: React.FC<{ alternative: string; group: Array<PollResponse> }> = ({ alternative, group }) => (
  <div key={alternative} className="card p-2">
    <div className="d-flex align-items-start">
      <div className="flex-grow-1">
        <h5>{alternative}</h5>
        {isEmpty(group) ? "-" : <PersonsInGroup responses={group} />}
      </div>
      {isEmpty(group) ? null : (
        <div className={"flex-shrink-0"}>
          <i className={"px-2"}>{group.length} st</i>
          <a
            className={"icon"}
            target={window.location.href}
            href={`mailto:${group.map((response) => response.email.emailAddress).join(",")}`}
            title={"Skapa mejl till svaranden"}
          >
            <FontAwesomeIcon icon={faEnvelope} size="1x" />
          </a>
        </div>
      )}
    </div>
  </div>
);

const PersonsInGroup: React.FC<{ responses: Array<PollResponse> }> = ({ responses }) => (
  <div className="d-flex gap-1 flex-column">
    {map(responses, (r) => (
      <div key={r.personUUID}>
        {r.firstName} {r.lastName}
      </div>
    ))}
  </div>
);

function formatDate(timeStamp: string | undefined) {
  if (!timeStamp) {
    return "";
  }
  const date = new Date(timeStamp);
  const month = date.getMonth();
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  return ` ${day}/${month} ${hour}:${minute}`;
}

function commentComparator(a: PollResponse, b: PollResponse) {
  // Parse the timestamps to Date objects, treating null or empty strings as smaller/earlier.
  const dateA = a.commentTimeStamp ? new Date(a.commentTimeStamp) : null;
  const dateB = b.commentTimeStamp ? new Date(b.commentTimeStamp) : null;

  if (dateA && dateB) {
    // If both have valid dates, sort them in ascending order
    return dateA.getTime() - dateB.getTime();
  } else if (!dateA && !dateB) {
    // If both are null/empty, consider them equal
    return 0;
  } else if (!dateA) {
    // If only A is null/empty, A should be considered smaller
    return -1;
  } else {
    // If only B is null/empty, B should be considered smaller
    return 1;
  }
}
