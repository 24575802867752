import { find, head } from "lodash";
import React, { useState } from "react";
import { BasicLinkData, Category } from "../../../../common/backend/model/Link.model";
import { ActionButton } from "../../../../common/components/buttons/ActionButton";
import { FileSelect } from "../../../../common/components/file-upload/FileSelect";
import { LabelledSelect } from "../../../../common/components/labelled-inputs/LabelledSelect";
import { LabelledTextInput } from "../../../../common/components/labelled-inputs/LabelledTextInput";
import { LinkEditorProps } from "./LinkEditor";
import { ConfirmRemoveModal } from "../../../../common/components/modal/ConfirmRemoveModal";
import { ValidationErrors, validationErrorsEmpty } from "../../../../common/backend/ValidationErrors";
import { useNotifications } from "../../../rootpage/Notifications";

export const FileLinkEditor: React.FC<LinkEditorProps> = (props: LinkEditorProps) => {
  const notifications = useNotifications();
  const {
    linkUUID,
    savedLink,
    onCreateFileLink = () => undefined,
    onRemoveLink = () => undefined,
    onUpdateLink = () => undefined,
    onClose = () => undefined,
    onValidateFileLink = () => Promise.resolve(validationErrorsEmpty()),
  } = props;

  const [editMode] = useState(!!linkUUID);
  const [modified, setModified] = useState(false);
  const [editedLink, setEditedLink] = useState<Partial<BasicLinkData>>(savedLink || { type: "FILE_LINK" });
  const [file, setFile] = useState<File>();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(validationErrorsEmpty());
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const options = [
    { id: Category.WEEKLY_LETTER, label: "Veckobrev" },
    { id: Category.POLICY_DOCUMENT, label: "Policy & kvalité" },
    { id: Category.CONTACTOR, label: "Contactor" },
    { id: Category.PRESENTATION, label: "Presentationer" },
    { id: Category.CAG, label: "CAG" },
  ];

  function updateEditedLink(fields: Partial<BasicLinkData>) {
    setEditedLink((prevValue) => {
      const newValue = {
        ...prevValue,
        ...fields,
      };
      return newValue;
    });
    setModified(true);
  }

  return (
    <>
      <div>
        <div className={"d-flex align-items-center gap-2"}>
          <FileSelect
            buttonLabel={"Ladda upp fil"}
            mimeTypes={["application/pdf"]}
            onSelectionChange={(files) => {
              setFile(head(files));
              setModified(true);
            }}
            disabled={editMode}
          />
          {!!file ? <p className={"text-truncate my-0 text-secondary flex-fill"}>{file?.name}</p> : null}
        </div>

        <LabelledTextInput
          focus={true}
          value={editedLink?.title}
          label="Titel"
          className="mb-2"
          errors={validationErrors.for("title")}
          onChange={(title) => updateEditedLink({ title })}
        />

        <LabelledTextInput
          focus={true}
          value={editedLink?.description}
          label="Beskrivning"
          className="mb-2"
          onChange={(description) => updateEditedLink({ description })}
        />

        <LabelledSelect
          label="Kategori"
          focus={true}
          value={find(options, (o) => o.id === editedLink?.category)}
          options={options}
          errors={validationErrors.for("category")}
          onChange={(o) => updateEditedLink({ category: o?.id as Category })}
          className="mb-2"
        />

        <div className="mt-3 d-flex flex-wrap gap-1 justify-content-end">
          {editMode ? (
            <ActionButton
              label="Ta bort"
              variant="secondary"
              className="btn-danger"
              onClick={() => setShowConfirmRemoveModal(true)}
            />
          ) : null}
          <ActionButton label="Avbryt" variant="secondary" onClick={() => onClose()} />
          <ActionButton
            label="Spara"
            disabled={!modified}
            onClick={async () => {
              const validationResult = await onValidateFileLink(editedLink as BasicLinkData);
              setValidationErrors(validationResult);
              if (!validationResult.hasErrors()) {
                if (editMode) {
                  onUpdateLink(linkUUID as string, editedLink as BasicLinkData);
                } else if (!!file) {
                  onCreateFileLink(editedLink as BasicLinkData, file);
                } else {
                  notifications.add({
                    title: "Fillänken kan inte sparas eftersom fil saknas",
                    autoRemove: true,
                    type: "ERROR",
                  });
                }
              }
            }}
          />
        </div>
      </div>
      <ConfirmRemoveModal
        nameOfTheThingToRemove={"länken"}
        show={showConfirmRemoveModal}
        confirmAction={() => {
          onRemoveLink(linkUUID as string, editedLink as BasicLinkData);
          setShowConfirmRemoveModal(false);
        }}
        dismissAction={() => setShowConfirmRemoveModal(false)}
      />
    </>
  );
};
