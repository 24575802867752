import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppContext } from "../../App.state";
import { useBackendApi } from "../../common/backend/backendApi";
import { CalendarAdministrationPage } from "../calendar/CalendarAdministrationPage";
import { LandingPage } from "../landing/LandingPage";
import { LinksPage } from "../links/LinksPage";
import { LinksAdministrationPage } from "../links/admin/LinksAdministrationPage";
import { PersonsAdministrationPage } from "../persons/PersonsAdministrationPage";
import { PollsAdministrationPage } from "../polls/PollsAdministrationPage";
import { Activities } from "./Activities";
import { MenuBar } from "./MenuBar";
import { Notifications } from "./Notifications";
import { TopBar } from "./TopBar";
import { AlbumAdministrationPage } from "../gallery/album/AlbumAdministrationPage";
import { MediaAdministrationPage } from "../gallery/media/MediaAdministrationPage";

export const RootPage: React.FC = () => {
  const appState = useContext(AppContext).state;
  const backendApi = useBackendApi();
  useEffect(() => {
    backendApi.persons.loadLoggedInPerson();
    backendApi.stock.loadStock();
  }, [backendApi.persons, backendApi.stock]);

  if (appState.loggedInPerson.loading) {
    return (
      <>
        <Activities />
        <Notifications />
      </>
    );
  }

  if (!appState.loggedInPerson.value) {
    return (
      <>
        <Activities />
        <div className="position-absolute top-50 start-50 translate-middle">
          <span className="fs-1">Logga in...</span>
        </div>
        <Notifications />
      </>
    );
  }

  return (
    <>
      <Activities />

      {
        <div className="dark">
          <TopBar person={appState.loggedInPerson.value} stockData={appState.stock.value} />

          <div className="mt-3">
            <Routes>
              <Route index element={<LandingPage />} />
              <Route path="/persons" element={<PersonsAdministrationPage />} />
              <Route path="/persons/:personId" element={<PersonsAdministrationPage />} />
              <Route path="/polls" element={<PollsAdministrationPage />} />
              <Route path="/polls/:uuid" element={<PollsAdministrationPage />} />
              <Route path="/calendar" element={<CalendarAdministrationPage />} />
              <Route path="/calendar/:eventId" element={<CalendarAdministrationPage />} />
              <Route path="/albums" element={<AlbumAdministrationPage />} />
              <Route path="/albums/images/:albumId" element={<MediaAdministrationPage />} />
              <Route path="/albums/images/:albumId/:imageId" element={<MediaAdministrationPage />} />
              <Route path="/links" element={<LinksPage />} />
              <Route path="/links/:uuid" element={<LinksPage />} />
              <Route path="/linksadmin" element={<LinksAdministrationPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>

          <MenuBar />
        </div>
      }
      <Notifications />
    </>
  );
};
