import { isEmpty } from "lodash";

export interface CalendarEvent {
  uuid: string;
  owner: { uuid: string | undefined };
  url: string;
  title: string;
  description?: string;
  createdBy: string; // person.uuid
  created: string; // Date
  eventDate: string; // DateTime;
}

export const emptyCalendarEvent: CalendarEvent = {
  uuid: "",
  owner: { uuid: "" },
  url: "",
  title: "",
  createdBy: "",
  created: "",
  eventDate: "",
};

export function isActive(calEvent: CalendarEvent): boolean {
  if (isEmpty(calEvent.eventDate)) {
    return false;
  }
  return new Date(calEvent.eventDate) > new Date();
}
