import React, { useState } from "react";
import { Loadable } from "../../../App.state";
import { Person } from "../../../common/backend/model/Person.model";
import { IncompletePollData, ValidPollData } from "../../../common/backend/model/Poll.model";
import { ActionButton } from "../../../common/components/buttons/ActionButton";
import { AlternativesInput } from "../../../common/components/labelled-inputs/AlternativesInput";
import { LabelledDateInput } from "../../../common/components/labelled-inputs/LabelledDateInput";
import { LabelledTextArea } from "../../../common/components/labelled-inputs/LabelledTextArea";
import { LabelledTextInput } from "../../../common/components/labelled-inputs/LabelledTextInput";
import { RespondantsSelect } from "../../../common/components/labelled-inputs/RespondantsSelect";
import { ValidationErrors, validationErrorsEmpty } from "../../../common/backend/ValidationErrors";
import { ConfirmRemoveModal } from "../../../common/components/modal/ConfirmRemoveModal";

export interface PollEditorProps {
  pollUUID?: string;
  savedPoll?: ValidPollData;
  persons: Loadable<Array<Person>>;
  onCreatePoll?: (pollData: ValidPollData) => void;
  onUpdatePoll?: (uuid: string, pollData: ValidPollData, sendNotification: boolean) => void;
  onRemovePoll?: (uuid: string) => void;
  onValidatePoll?: (pollData: IncompletePollData) => Promise<ValidationErrors>;
  onClose?: () => void;
}

export const PollEditor: React.FC<PollEditorProps> = (props: PollEditorProps) => {
  async function updateEditedPoll(fields: IncompletePollData) {
    setState((prevState) => {
      const newValue = {
        ...prevState,
        editedPoll: {
          ...prevState.editedPoll,
          ...fields,
        },
        modified: true,
      };
      return newValue;
    });
  }

  const {
    pollUUID,
    savedPoll,
    persons,
    onCreatePoll = () => undefined,
    onRemovePoll = () => undefined,
    onUpdatePoll = () => undefined,
    onClose = () => undefined,
    onValidatePoll = () => Promise.resolve(validationErrorsEmpty()),
  } = props;

  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const [state, setState] = useState({
    editedPoll: savedPoll || ({ responseOptions: ["Ja", "Nej"] } as IncompletePollData),
    showConfirmRemoveModal: false,
    validationErrors: validationErrorsEmpty(),
    saving: false,
    modified: false,
    sendNotification: false,
  });

  if (persons.loading || !persons.value) {
    return null;
  }
  return (
    <>
      <div className="p-2">
        <LabelledTextInput
          focus={true}
          value={state.editedPoll?.heading}
          label="Rubrik"
          className="mb-2"
          errors={state.validationErrors.for("heading")}
          onChange={(heading) => updateEditedPoll({ heading })}
        />
        <LabelledDateInput
          value={state.editedPoll?.startDate}
          label="Startdatum"
          className="mb-2"
          errors={state.validationErrors.for("startDate")}
          onChange={(startDate) => updateEditedPoll({ startDate })}
        />
        <LabelledDateInput
          value={state.editedPoll?.endDate}
          label="Slutdatum"
          className="mb-2"
          errors={state.validationErrors.for("endDate")}
          onChange={(endDate) => updateEditedPoll({ endDate })}
        />
        <AlternativesInput
          label="Svarsalternativ"
          className="mb-2"
          value={state.editedPoll?.responseOptions}
          errors={state.validationErrors.for("responseOptions")}
          onChange={(answerAlternatives) => updateEditedPoll({ responseOptions: answerAlternatives })}
        />
        <LabelledTextArea
          value={state.editedPoll?.detailText}
          label="Frivillig extratext (valfritt)"
          onChange={(detailText) => updateEditedPoll({ detailText })}
        />
        <RespondantsSelect
          label="Tillfrågade"
          selectablePersons={persons.value}
          selectedRespondants={state.editedPoll?.respondants}
          errors={state.validationErrors.for("pollResponses")}
          onChange={(respondants) => updateEditedPoll({ respondants })}
        />

        {pollUUID && (
          <div className="form-check mb-2" style={{ marginTop: "1rem" }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="sendNotification"
              checked={state.sendNotification}
              onChange={(e) => setState((prev) => ({ ...prev, sendNotification: e.target.checked }))}
            />
            <label
              className="form-check-label"
              htmlFor="sendNotification"
              style={{ fontSize: "0.875rem", color: "inherit", marginLeft: "0.5rem" }}
            >
              Notis på Slack
            </label>
          </div>
        )}
        <div className="mt-3 d-flex flex-wrap gap-1 justify-content-end">
          {savedPoll ? (
            <ActionButton
              label="Ta bort"
              variant="secondary"
              className="btn-danger"
              onClick={() => setShowConfirmRemoveModal(true)}
            />
          ) : null}
          <ActionButton label="Avbryt" variant="secondary" onClick={() => onClose()} />
          <ActionButton
            label="Spara"
            disabled={!state.modified || state.saving}
            onClick={async () => {
              const validationResult = await onValidatePoll(state.editedPoll);
              setState((prev) => ({ ...prev, validationErrors: validationResult }));
              if (!validationResult.hasErrors()) {
                setState((prev) => ({ ...prev, saving: true }));
                if (pollUUID) {
                  onUpdatePoll(
                    pollUUID,
                    state.editedPoll as ValidPollData /* If the poll is valid it is complete */,
                    state.sendNotification
                  );
                } else {
                  onCreatePoll(state.editedPoll as ValidPollData /* If the poll is valid it is complete */);
                }
              }
            }}
          />
        </div>
      </div>
      <ConfirmRemoveModal
        nameOfTheThingToRemove={"frågan"}
        show={showConfirmRemoveModal}
        confirmAction={() => {
          if (pollUUID) {
            onRemovePoll(pollUUID);
          }
          setShowConfirmRemoveModal(false);
        }}
        dismissAction={() => setShowConfirmRemoveModal(false)}
      />
    </>
  );
};
