import { CalendarEvent } from "./CalenderEvent.model";
import { MultilineText } from "../../common/components/presentation/MultilineText";
import { ActionButton } from "../../common/components/buttons/ActionButton";
import { isEmpty } from "lodash";
import { Group } from "../../common/backend/model/Person.model";
import { AppContext } from "../../App.state";
import { useContext } from "react";

interface CalendarEventViewerProps {
  calendarEvent: CalendarEvent | undefined;
  onEdit: (eventId: string) => void;
}

function CalendarEventViewer({ calendarEvent, onEdit }: CalendarEventViewerProps) {
  const user = useContext(AppContext).state.loggedInPerson.value;

  if (!calendarEvent) return null;

  const dateStringOf = (dateStr: string) => {
    if (isEmpty(dateStr)) {
      return "";
    }
    return new Intl.DateTimeFormat("sv", {
      weekday: "short",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(new Date(dateStr));
  };

  const isEventEditable = () => {
    return user ? user.group === Group.ADMIN || user.uuid === calendarEvent.owner.uuid : false;
  };

  return (
    <>
      <div id="calendarEventViewer" className="p-2">
        <div className="d-flex gap-1 align-items-start border-bottom border-1 border-secondary">
          <h4 className="flex-grow-1 ">{calendarEvent.title}</h4>
          {isEventEditable() ? (
            <ActionButton variant="outline-primary" size="sm" onClick={() => onEdit(calendarEvent.uuid)}>
              Ändra
            </ActionButton>
          ) : null}
        </div>
        <br />
        <h5>{dateStringOf(calendarEvent.eventDate)}</h5>
        <MultilineText>{calendarEvent.description}</MultilineText>
        {!isEmpty(calendarEvent.url) ? <a href={calendarEvent.url}>{calendarEvent.title}</a> : null}
        <p className="pt-2">
          <em>{calendarEvent.createdBy}</em>
        </p>
        <p className="pt-2">
          <a href={"/api/v1/calendar/events/ics/" + calendarEvent.uuid} download={calendarEvent.title + ".ics"}>
            Hämta kalenderbokning
          </a>
        </p>
      </div>
    </>
  );
}

export default CalendarEventViewer;
