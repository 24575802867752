import React, { useEffect, useState } from "react";
import { useAppState } from "../../App.state";
import { useBackendApi } from "../../common/backend/backendApi";
import { StackLayout } from "../../common/components/layout/StackLayout";
import { CategoryLinks } from "./CategoryLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

export const LinksPage: React.FC = () => {
  const appState = useAppState().state;
  const backendApi = useBackendApi();
  const navigate = useNavigate();
  useEffect(() => {
    backendApi.links.loadLinks();
  }, [backendApi.links]);

  const [urlLinkOpened, setUrlLinkOpened] = useState(false);
  let { uuid: linkUuid } = useParams();
  useEffect(() => {
    if (!urlLinkOpened && linkUuid && appState.links.value) {
      setUrlLinkOpened(true);
      window.open(appState.links.value?.filter((link) => link.uuid === linkUuid)[0].url, "_self");
    }
  }, [urlLinkOpened, linkUuid, appState.links.value]);

  if (
    appState.loggedInPerson.loading ||
    appState.links.loading ||
    !appState.loggedInPerson.value ||
    !appState.links.value
  ) {
    return null;
  }

  return (
    <main className="container p-2">
      <div className="fw-lighter text-muted text-end pb-1 px-2">
        <div className={"icon click-target"} title={"Administrera länkar"} onClick={() => navigate("/linksadmin")}>
          <FontAwesomeIcon icon={faGear} size="1x" />
        </div>
      </div>

      <StackLayout gap={1}>
        <CategoryLinks category={"WEEKLY_LETTER"} title={"Veckobrev"} links={appState.links.value} />
        <CategoryLinks category={"PRESENTATION"} title={"Presentationer"} links={appState.links.value} />
        <CategoryLinks category={"CONTACTOR"} title={"Contactor"} links={appState.links.value} />
        <CategoryLinks category={"CAG"} title={"CAG"} links={appState.links.value} />
        <CategoryLinks category={"POLICY_DOCUMENT"} title={"Policy & kvalité"} links={appState.links.value} />
      </StackLayout>
    </main>
  );
};
