import { Loadable } from "../../../App.state";
import { Album } from "../../../common/backend/model/Album.model";
import { Col, Container, Row } from "react-bootstrap";
import { AlbumCard } from "./AlbumCard";

export interface AlbumGridProps {
  albums: Loadable<Array<Album>>;
  onOpenEditAlbum: (albumUuid: string) => void;
}
export const AlbumGrid: React.FC<AlbumGridProps> = (props) => {
  const { albums, onOpenEditAlbum } = props;

  if (albums.loading || !albums.value) {
    return null;
  }

  return (
    <Container fluid className="p-0">
      <Row xs={2} sm={3} md={4} lg={7} className="g-3">
        {albums.value.map((a) => (
          <Col key={a.uuid}>
            <AlbumCard album={a} onOpenEditAlbum={onOpenEditAlbum} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
