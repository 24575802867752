import { ErrorFeedbackText } from "./ErrorFeedbackText";
import React, { useRef, useState } from "react";
import { ValidationErrors, validationErrorsEmpty } from "../../backend/ValidationErrors";

export interface ErrorFeedbackProps {
  onModified?: () => void;
  errorBorderClass?: string;
}

export function withErrorFeedback<P>(WrappedComponent: React.FunctionComponent<P & ErrorFeedbackProps>) {
  return (props: P & { errors?: ValidationErrors }) => {
    const { errors = validationErrorsEmpty() } = props;
    const [modified, setModified] = useState(false);
    const prevErrorsRef = useRef(errors);

    let showError = errors.hasErrors() && !modified;

    if (!errors.equals(prevErrorsRef.current)) {
      prevErrorsRef.current = errors;
      setModified(false);
    }

    return (
      <>
        <ErrorFeedbackText errors={errors} show={showError} />
        <WrappedComponent
          {...props}
          onModified={() => setModified(true)}
          errorBorderClass={showError ? "border-danger" : ""}
        />
      </>
    );
  };
}
