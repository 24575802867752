import React, { useState } from "react";
import { Person } from "../../common/backend/model/Person.model";
import { SavedPoll, UpdatePollResponseCommand } from "../../common/backend/model/Poll.model";
import { LabelledSelect, Option } from "../../common/components/labelled-inputs/LabelledSelect";
import { ActionButton } from "../../common/components/buttons/ActionButton";

export interface ChangePollAnswerProps {
  persons: Person[];
  poll: SavedPoll;
  onChangePollAnswer: (
    pollId: string,
    userId: string,
    updatePollResponseCommand: UpdatePollResponseCommand
  ) => Promise<void>;
  onClose: () => void;
}

export const ChangePollAnswer: React.FC<ChangePollAnswerProps> = (props: ChangePollAnswerProps) => {
  const { persons, poll, onChangePollAnswer, onClose } = props;
  const [selectedPerson, setSelectedPerson] = useState<Option | undefined>();
  const [selectedPersonEmail, setSelectedPersonEmail] = useState<string | undefined>();
  const [selectedAnswer, setSelectedAnswer] = useState<Option | undefined>();

  // Only show persons that have answered the poll
  const personOptions = persons
    .filter((person) => poll.responses.find((response) => response.email.emailAddress === person.email.emailAddress))
    .map((person) => ({
      id: person.uuid,
      label: `${person.firstName} ${person.lastName}`,
    }));

  const pollAnswerOptions = poll.responseOptions.map((answer) => ({
    id: answer,
    label: answer,
  }));

  return (
    <div className="p-2">
      <div className="d-flex gap-1 align-items-start border-bottom border-1 border-secondary">
        <h4 className="flex-grow-1 ">{poll.heading}</h4>
      </div>
      <br />
      <div className="d-flex gap-2 flex-column mt-3">
        <LabelledSelect
          label="Person"
          value={selectedPerson}
          options={personOptions}
          onChange={(option) => {
            setSelectedPerson(option);
            const selectedPersonEmail = persons.find((person) => person.uuid === option?.id)?.email.emailAddress;
            setSelectedPersonEmail(selectedPersonEmail);
          }}
        />
        <LabelledSelect label="Svar" value={selectedAnswer} options={pollAnswerOptions} onChange={setSelectedAnswer} />
      </div>
      <div className="mt-3 d-flex flex-wrap gap-1 justify-content-end">
        <ActionButton label="Avbryt" variant="secondary" onClick={() => onClose()} />
        <ActionButton
          label="Spara"
          onClick={() => {
            if (!selectedPerson || !selectedAnswer || !selectedPersonEmail) {
              console.error("Both a person and an answer must be selected before saving.");
            } else {
              onChangePollAnswer(poll.uuid, selectedPersonEmail, {
                selectedAlternative: selectedAnswer.id,
                comment: "Ändrad av admin",
              });
            }
          }}
        />
      </div>
    </div>
  );
};
