import { Album, AlbumData } from "../../../common/backend/model/Album.model";
import { inOffCanvasHOC } from "../../../common/components/offcanvas/InOffCanvasHOC";
import { AlbumEditor } from "./AlbumEditor";
import { AlbumGrid } from "./AlbumGrid";
import { FloatingActionButton } from "../../../common/components/buttons/FloatingActionButton";
import { find } from "lodash";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App.state";
import { useBackendApi } from "../../../common/backend/backendApi";

interface InternalState {
  showCreateAlbumForm: boolean;
  showEditAlbumForm: boolean;
  selectedAlbum?: Album;
}

const AlbumCreateFormInOffCanvas = inOffCanvasHOC(AlbumEditor, "end", "Skapa nytt album");
const AlbumEditFormInOffCanvas = inOffCanvasHOC(AlbumEditor, "end", "Ändra album");

export const AlbumAdministrationPage: React.FC = () => {
  const controller = useGalleryAdministrationPageController();

  if (controller.albums.loading) {
    return null;
  }

  return (
    <main className="container p-2">
      <AlbumGrid albums={controller.albums} onOpenEditAlbum={(albumId) => controller.openEditAlbumForm(albumId)} />

      <FloatingActionButton label="Lägg till" bottom={70} onClick={controller.openCreateAlbumForm} />

      <AlbumCreateFormInOffCanvas
        show={controller.showCreateAlbumForm}
        onHide={controller.closeForms}
        onCreateAlbum={controller.createAlbum}
        onClose={controller.closeForms}
        onValidateAlbum={controller.validateAlbum}
      />

      <AlbumEditFormInOffCanvas
        show={controller.showEditAlbumForm}
        onHide={controller.closeForms}
        onUpdateAlbum={controller.updateAlbum}
        onRemoveAlbum={controller.deleteAlbum}
        albumUUID={controller.selectedAlbum?.uuid}
        savedAlbum={controller.selectedAlbum}
        onClose={controller.closeForms}
        onValidateAlbum={controller.validateAlbum}
      />
    </main>
  );
};

function useGalleryAdministrationPageController() {
  const appState = useContext(AppContext).state;
  const backendApi = useBackendApi();

  useEffect(() => {
    backendApi.gallery.loadAlbums();
  }, [backendApi.gallery]);

  const [state, setState] = useState<InternalState>({
    showCreateAlbumForm: false,
    showEditAlbumForm: false,
  });

  function closeCreateAlbumForm() {
    setState((prev) => ({
      ...prev,
      showCreateAlbumForm: false,
    }));
  }

  function closeEditAlbumForm() {
    setState((prev) => ({
      ...prev,
      showEditAlbumForm: false,
    }));
  }

  return {
    albums: appState.albums,
    showCreateAlbumForm: state.showCreateAlbumForm,
    showEditAlbumForm: state.showEditAlbumForm,
    selectedAlbum: state.selectedAlbum,
    openCreateAlbumForm: () =>
      setState((prev) => ({
        ...prev,
        showCreateAlbumForm: true,
        showEditAlbumForm: false,
      })),
    openEditAlbumForm: (selectedAlbumUuid: string) => {
      const selectedAlbum = find(appState.albums.value, (a) => a.uuid === selectedAlbumUuid);
      if (selectedAlbum) {
        setState((prev) => ({
          ...prev,
          showEditAlbumForm: true,
          showCreateAlbumForm: false,
          selectedAlbum,
        }));
      }
    },
    createAlbum: async (albumData: AlbumData, files: File[]) => {
      await backendApi.gallery.createAlbum(albumData, files);
      closeCreateAlbumForm();
    },
    updateAlbum: async (albumUuid: string, albumData: AlbumData, files: File[]) => {
      await backendApi.gallery.updateAlbum(albumUuid, albumData, files);
      closeEditAlbumForm();
    },
    deleteAlbum: async (albumUuid: string) => {
      await backendApi.gallery.deleteAlbum(albumUuid);
      closeEditAlbumForm();
    },
    validateAlbum: async (albumData: Partial<AlbumData>) => {
      return await backendApi.gallery.validateAlbum(albumData);
    },

    closeForms: () =>
      setState((prev) => ({
        ...prev,
        showCreateAlbumForm: false,
        showEditAlbumForm: false,
      })),
  };
}
