import { isEmpty, keys, map, values } from "lodash";
import React, { Dispatch, SetStateAction, useState } from "react";
import { AppState, useAppState } from "../../App.state";
import { StackLayout } from "../../common/components/layout/StackLayout";

export interface ActivitiesState {
  hide: boolean;
  timer: ReturnType<typeof setTimeout> | null;
}

export const Activities: React.FC = () => {
  const appState = useAppState().state;
  const [state, setState] = useState<ActivitiesState>({ hide: true, timer: null });

  updateHideState(state, setState, appState);

  return (
    <>
      {!state.hide ? (
        <div className="position-fixed" style={{ zIndex: 10000 }}>
          <div className="vw-100 vh-100 d-flex justify-content-center align-items-center blur-background">
            <StackLayout center={true} gap={2}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Kör aktivitet...</span>
              </div>
              {map(values(appState.ongoingActivities), (a) => (
                <div key={a?.activityName}>{a?.activityName}</div>
              ))}
            </StackLayout>
          </div>
        </div>
      ) : null}
    </>
  );
};

export type SetStateFn = Dispatch<SetStateAction<ActivitiesState>>;

export function updateHideState(state: ActivitiesState, setState: SetStateFn, appState: AppState) {
  function shouldHide() {
    return isEmpty(keys(appState.ongoingActivities));
  }

  if (shouldHide() && state.hide && state.timer === null) {
    return;
  }

  if (!shouldHide() && state.hide && state.timer === null) {
    const timer = setTimeout(() => {
      if (shouldHide()) {
        // Extra säkerhet om det blir något race condition
        setState({ hide: true, timer: null });
      } else {
        setState({ hide: false, timer: null });
      }
    }, 500);
    setState((prev) => ({ ...prev, timer }));
    return;
  }

  if (shouldHide()) {
    if (state.timer) {
      clearTimeout(state.timer);
    }

    if (!state.hide || state.timer) {
      setState({ hide: true, timer: null });
      return;
    }
  }
}
