import { Card } from "react-bootstrap";
import { Person } from "../../common/backend/model/Person.model";
import React from "react";

export interface PersonCardProps {
  person: Person;
  onOpenEditPerson: (person: Person) => void;
  onOpenViewPerson: (person: Person) => void;
}

export const PersonCard: React.FC<PersonCardProps> = (props) => {
  const { person, onOpenViewPerson } = props;
  return (
    <Card className="mb-2 shadow-lg click-target" onClick={() => onOpenViewPerson(person)}>
      <div className="d-flex gap-2 flex-wrap p-2">
        <img
          src={person.imageLink}
          className="rounded-pill shadow-lg"
          style={{ objectFit: "cover" }}
          height="80"
          width="80"
          alt={person.firstName}
        />
        <div className="flex-grow-1 p-1">
          <div>
            {person.firstName} {person.lastName}
          </div>
          <div>{person.email?.emailAddress}</div>
          <div>{person.phoneNumber?.number}</div>
        </div>
      </div>
    </Card>
  );
};
