import { initialsOf, Person } from "../../common/backend/model/Person.model";
import { StackLayout } from "../../common/components/layout/StackLayout";
import { ActionButton } from "../../common/components/buttons/ActionButton";
import { translateGroup } from "../../common/utils/translateGroup";
import { translateRole } from "../../common/utils/translateRole";
import { FaEnvelope, FaHome, FaPhone, FaUsers, FaUserTag } from "react-icons/fa";

import React from "react";

interface PersonViewProps {
  person?: Person;
  onOpenEditPerson: (person: Person | undefined) => void;
}

export const PersonView: React.FC<PersonViewProps> = (props) => {
  const { person, onOpenEditPerson } = props;
  return (
    <div className="p-2">
      <StackLayout gap={4}>
        {person ? (
          <>
            <div className="d-flex gap-1 justify-content-end">
              {person.allowedToEdit ? (
                <ActionButton variant="outline-primary" size="sm" onClick={() => onOpenEditPerson(person)}>
                  Ändra
                </ActionButton>
              ) : null}
            </div>
            <div className="d-flex flex-wrap justify-content-center my-3">
              {person.imageLink ? (
                <img src={person.imageLink} alt="Bild på person" className="rounded-pill w-50 h-75" />
              ) : (
                <div className="p-3 border-0 rounded-circle bg-secondary" style={{ fontSize: "4rem" }}>
                  {initialsOf(person)}
                </div>
              )}
            </div>
            <dl>
              <dd style={{ fontSize: "1.5rem" }}>
                {person.firstName || "-"} {person.lastName || "-"}
              </dd>
              <dd className="d-flex align-items-center">
                <FaPhone className="me-2" /> {/* Phone icon */}
                {person.phoneNumber ? (
                  <a href={`tel:${person.phoneNumber?.number}`} style={{ textDecoration: "none" }}>
                    {person.phoneNumber?.number}
                  </a>
                ) : (
                  "-"
                )}
              </dd>
              <dd className="d-flex align-items-center">
                <FaEnvelope className="me-2" />
                {person.email ? (
                  <a href={`mailto:${person.email?.emailAddress}`} style={{ textDecoration: "none" }}>
                    {person.email?.emailAddress}
                  </a>
                ) : (
                  "-"
                )}
              </dd>
              <fieldset>
                <dl>
                  {person.address ? (
                    <>
                      <dd className="d-flex align-items-center" style={{ marginBottom: "0rem" }}>
                        <FaHome className="me-2" />
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${person.address.street},+${person.address.zipCode}+${person.address.city}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <span>
                            {person.address.street}, {person.address.zipCode} {person.address.city}
                          </span>
                        </a>
                      </dd>
                    </>
                  ) : (
                    <>
                      <dd className="d-flex align-items-center" style={{ marginBottom: "0rem" }}>
                        <FaHome className="me-2" />
                        <span>-</span>
                      </dd>
                      <dd style={{ paddingLeft: "23px" }}>-</dd>
                    </>
                  )}
                </dl>
              </fieldset>
              <dd>
                <FaUsers className="me-2" />
                {translateGroup(person.group) || "-"}
              </dd>
              <dd>
                <FaUserTag className="me-2" />
                {translateRole(person.role) || "-"}
              </dd>
            </dl>
          </>
        ) : undefined}
      </StackLayout>
    </div>
  );
};
