import { CSSProperties, FC } from "react";
import { ActionButton, ActionButtonProps } from "./ActionButton";

interface FloatingActionButtonProps extends ActionButtonProps {
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
}

export const FloatingActionButton: FC<FloatingActionButtonProps> = (props: FloatingActionButtonProps) => {
  const { label, className, style, variant, top, bottom, right, left, onClick } = props;

  const buttonStyle: CSSProperties = {
    position: "fixed",
    right: !left && !right ? 20 : right,
    left: !left ? undefined : left,
    top: !top ? undefined : top,
    bottom: !top && !bottom ? 20 : bottom,
  };
  return (
    <>
      <ActionButton
        style={{ ...buttonStyle, ...style }}
        className={className}
        variant={variant}
        label={label}
        onClick={onClick}
      />
    </>
  );
};
