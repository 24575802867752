import React from "react";
import { DateInputUtil } from "../../date";
import { LabelledInput } from "./new-input/LabelledInput";
import { LabelledInputBaseProps } from "./new-input/LabelledInputBaseProps";
import { ErrorFeedbackProps, withErrorFeedback } from "../validation/WithErrorFeedback";

export interface DateInputProps extends LabelledInputBaseProps, ErrorFeedbackProps {
  value?: Date | string;
  includeTime?: boolean;
  onChangeDate?: (value?: Date) => void;
  onChange?: (value?: string) => void;
}

const Component: React.FC<DateInputProps> = (props) => {
  const { value, onChange = () => undefined, onChangeDate = () => undefined } = props;

  return (
    <>
      <LabelledInput
        {...props}
        type={props.includeTime ? "datetime-local" : "date"}
        value={DateInputUtil.format(value, props.includeTime)}
        onChange={(dateStr) => {
          onChange(dateStr);
          onChangeDate(dateStr ? new Date(dateStr) : undefined);
        }}
      ></LabelledInput>
    </>
  );
};

export const LabelledDateInput = withErrorFeedback(Component);
