import { find } from "lodash";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App.state";
import { useBackendApi } from "../../../common/backend/backendApi";
import { BasicLinkData, SavedLink } from "../../../common/backend/model/Link.model";
import { FloatingActionButton } from "../../../common/components/buttons/FloatingActionButton";
import { inOffCanvasHOC } from "../../../common/components/offcanvas/InOffCanvasHOC";
import { LinkList } from "./LinkList";
import { LinkEditor } from "./link-editor/LinkEditor";

interface InternalState {
  showCreateLinkForm: boolean;
  showModifyLinkForm: boolean;
  selectedLink?: SavedLink;
}

export const LinksAdministrationPage: React.FC = () => {
  const controller = useLinksAdministrationPageController();

  if (controller.loggedInPerson.loading || controller.links.loading) {
    return null;
  }
  if (!controller.loggedInPerson.value) {
    return <div>Du är inte inloggad!</div>;
  }

  return (
    <main className="container p-2">
      <LinkList links={controller.links} onOpenLink={(linkId) => controller.openLinkEditor(linkId)} />

      <FloatingActionButton label="Lägg till" bottom={70} onClick={controller.openCreateLinkForm} />

      <CreateLinkFormInOffCanvas
        show={controller.showCreateLinkForm}
        links={controller.links}
        onHide={controller.closeForms}
        onCreateLink={controller.createLink}
        onCreateFileLink={controller.createFileLink}
        onValidateUrlLink={controller.validateUrlLink}
        onValidateFileLink={controller.validateFileLink}
        onClose={controller.closeForms}
      />

      {controller.selectedLink ? (
        <>
          <ModifyLinkFormInOffCanvas
            show={controller.showModifyLinkForm}
            linkUUID={controller.selectedLink.uuid}
            savedLink={controller.selectedLink}
            links={controller.links}
            onHide={controller.closeForms}
            onUpdateLink={controller.updateLink}
            onRemoveLink={controller.deleteLink}
            onValidateUrlLink={controller.validateUrlLink}
            onValidateFileLink={controller.validateFileLink}
            onClose={controller.closeForms}
          />
        </>
      ) : null}
    </main>
  );
};

const CreateLinkFormInOffCanvas = inOffCanvasHOC(LinkEditor, "end", "Skapa ny länk");
const ModifyLinkFormInOffCanvas = inOffCanvasHOC(LinkEditor, "end", "Ändra länk");

function useLinksAdministrationPageController() {
  const appState = useContext(AppContext).state;
  const backendApi = useBackendApi();

  useEffect(() => {
    backendApi.links.loadLinks();
  }, [backendApi.links]);

  const [state, setState] = useState<InternalState>({
    showCreateLinkForm: false,
    showModifyLinkForm: false,
  });

  return {
    loggedInPerson: appState.loggedInPerson,
    openLinkEditor: (selectedLinkId: string) => {
      const selectedLink: SavedLink | undefined = find(appState.links.value, (l) => l.uuid === selectedLinkId);
      if (selectedLink) {
        setState((prev) => ({
          ...prev,
          showModifyLinkForm: true,
          showCreateLinkForm: false,
          selectedLink,
        }));
      }
    },

    createLink: async (link: BasicLinkData) => {
      await backendApi.links.createLink(link);
      setState((prev) => ({ ...prev, showCreateLinkForm: false }));
    },
    createFileLink: async (link: BasicLinkData, file: File) => {
      await backendApi.links.createFileLink(link, file);
      setState((prev) => ({ ...prev, showCreateLinkForm: false }));
    },

    showModifyLinkForm: state.showModifyLinkForm,
    updateLink: async (uuid: string, link: BasicLinkData) => {
      await backendApi.links.updateLink(uuid, link);
      setState((prev) => ({ ...prev, showModifyLinkForm: false }));
    },
    deleteLink: async (uuid: string) => {
      await backendApi.links.deleteLink(uuid);
      setState((prev) => ({ ...prev, showModifyLinkForm: false }));
    },
    validateUrlLink: async (link: BasicLinkData) => {
      return await backendApi.links.validateUrlLink(link);
    },
    validateFileLink: async (link: BasicLinkData) => {
      return await backendApi.links.validateFileLink(link);
    },

    selectedLink: state.selectedLink,

    showCreateLinkForm: state.showCreateLinkForm,
    openCreateLinkForm: () =>
      setState((prev) => ({
        ...prev,
        showCreateLinkForm: true,
        showModifyLinkForm: false,
      })),

    links: appState.links,

    closeForms: () =>
      setState((prev) => ({
        ...prev,
        showModifyLinkForm: false,
        showCreateLinkForm: false,
      })),
  };
}
