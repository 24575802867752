import React, { useReducer } from "react";
import "./App.css";
import { AppContext, appReducer, INITIAL_STATE } from "./App.state";
import { RootPage } from "./pages/rootpage/RootPage";

export const App: React.FC = () => {
  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);
  return (
    <>
      <AppContext.Provider value={{ state, dispatch }}>
        <RootPage />
      </AppContext.Provider>
    </>
  );
};

export default App;
