import { isEmpty, sortBy } from "lodash";
import { Alert } from "react-bootstrap";
import { Loadable } from "../../App.state";
import { dateString } from "../../common/date";
import { CalendarEvent, isActive } from "./CalenderEvent.model";
import { FloatingActionButton } from "../../common/components/buttons/FloatingActionButton";

export interface CalendarEventListProps {
  events: Loadable<Array<CalendarEvent>>;
  oldEvents: Loadable<Array<CalendarEvent>>;
  onViewEvent: (eventId: string) => void;
  onNewEvent: () => void;
}

export function CalendarEventList({ events, oldEvents, onViewEvent, onNewEvent }: CalendarEventListProps) {
  if (events.loading) {
    return <div>Laddar lista...</div>;
  }
  if (!events.value) {
    return null;
  }

  let eventList = sortBy(events.value, (calEvent) => calEvent.eventDate);
  let oldEventList = sortBy(oldEvents.value, (calEvent) => calEvent.eventDate);
  return (
    <>
      <FloatingActionButton label="Lägg till" bottom={70} onClick={onNewEvent} />
      <div className="d-flex flex-column gap-2">
        <div className="card ">
          {isEmpty(eventList) ? (
            <Alert variant="info">Inga kalenderhändelser</Alert>
          ) : (
            <div className="card-header">Aktuella händelser</div>
          )}
          {eventList.map((calEvent) => (
            <div className=" p-2 click-target" key={calEvent.uuid} onClick={() => onViewEvent(calEvent.uuid)}>
              <div className="fs-5 d-flex">
                <div className="flex-grow-1">{calEvent.title}</div>
                {isActive(calEvent) ? (
                  <div
                    className="badge bg-danger"
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      fontSize: "0.75rem",
                      fontWeight: "light",
                    }}
                  ></div>
                ) : null}
              </div>
              <div className="fw-light fst-italic">{dateString(calEvent.eventDate)}</div>
            </div>
          ))}
        </div>
        <div className="card">
          {isEmpty(oldEventList) ? (
            <Alert variant="info">Inga gamla kalenderhändelser</Alert>
          ) : (
            <div className="card-header">Gamla, ej längre aktuella händelser</div>
          )}
          {oldEventList.map((calEvent) => (
            <div className="p-2 click-target" key={calEvent.uuid} onClick={() => onViewEvent(calEvent.uuid)}>
              <div className="fs-5 d-flex">
                <div className="flex-grow-1">{calEvent.title}</div>
                {isActive(calEvent) ? (
                  <div
                    className="badge bg-danger"
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      fontSize: "0.75rem",
                      fontWeight: "light",
                    }}
                  ></div>
                ) : null}
              </div>
              <div className="fw-light fst-italic">{dateString(calEvent.eventDate)}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
