import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import React from "react";
import { StackLayout } from "../../common/components/layout/StackLayout";
import { SavedLink } from "../../common/backend/model/Link.model";

export const CategoryLinks: React.FC<{ category: string; title: string; links: Array<SavedLink> }> = ({
  category,
  title,
  links,
}) => {
  return (
    <div className="card shadow-lg">
      {" "}
      <div className="card-header">{title}</div>{" "}
      <div className="card-body">
        <StackLayout gap={1}>
          <div className="container pt-2">
            {map(
              links.filter((link) => link.category === category),
              (link) => (
                <div key={link.uuid}>
                  <a href={link.url} target={link.uuid}>
                    {link.title}
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ms-1" />
                  </a>
                </div>
              )
            )}
          </div>
        </StackLayout>
      </div>
    </div>
  );
};
