import {
  faCalendarDays,
  faHome,
  faImages,
  faPeopleGroup,
  faSquarePollVertical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "./MenuBar.scss";

export const MenuBar: React.FC = () => {
  return (
    <>
      {/*Spacer to make sure we can scroll all the way down
      since the sticky bottom MenuBar might cover the contents
       above it*/}
      <div style={{ height: "3rem" }} />

      <nav className="position-fixed bottom-0 w-100 bg-dark text-light p-2 d-flex flex-wrap justify-content-around menu-bar">
        <Link to="" className="text-light">
          <FontAwesomeIcon icon={faHome} size="2x" />
        </Link>
        <Link to="persons" className="text-light">
          <FontAwesomeIcon icon={faPeopleGroup} size="2x" />
        </Link>
        <Link to="polls" className="text-light">
          <FontAwesomeIcon icon={faSquarePollVertical} size="2x" />
        </Link>
        <Link to="calendar" className="text-light">
          <FontAwesomeIcon icon={faCalendarDays} size="2x" />
        </Link>
        <Link to="albums" className="text-light">
          <FontAwesomeIcon icon={faImages} size="2x" />
        </Link>
      </nav>
    </>
  );
};
