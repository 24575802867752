import { head } from "lodash";
import React, { useContext, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { AppContext, IdentifiableNotification } from "../../App.state";

export interface Notification {
  type: "ERROR" | "SUCCESS";
  title: string;
  details?: string;
  autoRemove?: boolean;
}

export function useNotifications() {
  const appContext = useContext(AppContext);
  return {
    add: (notification: Notification) => appContext.dispatch({ type: "AddNotification", notification }),
  };
}

export const Notifications: React.FC = () => {
  const appContext = useContext(AppContext);

  return (
    <>
      <NotificationsPopup
        notifications={appContext.state.notifications}
        onDismissNotification={(notification) => appContext.dispatch({ type: "RemoveNotification", notification })}
      />
    </>
  );
};

export interface NotificationsPopupProps {
  show?: boolean;
  notifications?: Array<IdentifiableNotification>;
  onDismissNotification?: (n: IdentifiableNotification) => void;
}

export const NotificationsPopup: React.FC<NotificationsPopupProps> = (props: NotificationsPopupProps) => {
  const { notifications = [], onDismissNotification = () => undefined } = props;
  const topNotification = head(notifications);
  useEffect(() => {
    if (topNotification?.autoRemove) {
      setTimeout(() => onDismissNotification(topNotification), 3000);
    }
  }, [topNotification, onDismissNotification]);

  if (!topNotification) {
    return null;
  }

  return (
    <Offcanvas
      show={true}
      placement="bottom"
      className={`text-white ${classFrom(topNotification)}`}
      onHide={() => onDismissNotification(topNotification)}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{topNotification.title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="text-sm-start">{topNotification.details}</div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

function classFrom(topNotification: Notification) {
  switch (topNotification.type) {
    case "SUCCESS":
      return "bg-success";
    case "ERROR":
      return "bg-danger";
  }
}
