import { isEmpty, map } from "lodash";
import React, { useState } from "react";
import { Person } from "../../backend/model/Person.model";
import { Respondant } from "../../backend/model/Poll.model";
import { ActionButton } from "../buttons/ActionButton";
import "./LabelledInput.scss";
import { LabelledInputBaseProps } from "./new-input/LabelledInputBaseProps";
import { SelectRespondantsModal } from "../modal/SelectRespondantsModal";
import { ErrorFeedbackProps, withErrorFeedback } from "../validation/WithErrorFeedback";

export interface RespondantsSelectProps extends LabelledInputBaseProps, ErrorFeedbackProps {
  selectedRespondants?: Array<Respondant>;
  selectablePersons?: Array<Person>;
  onChange?: (respondents: Array<Respondant>) => void;
}

const Component: React.FC<RespondantsSelectProps> = (props: RespondantsSelectProps) => {
  function updateRespondants(selectedRespondants: Array<Respondant>) {
    setInternalValue(selectedRespondants);
    onChange(selectedRespondants);
    onModified();
    setShowModal(false);
  }

  const {
    className,
    label,
    selectedRespondants = [],
    selectablePersons = [],
    onChange = () => undefined,
    onModified = () => undefined,
    errorBorderClass,
  } = props;

  const [internalValue, setInternalValue] = useState<Array<Respondant>>(selectedRespondants);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={`${className} input`}>
      <div className={`${errorBorderClass} input__field border rounded-1`} style={{ minHeight: "2.125rem" }}>
        <div className="d-flex align-items-start">
          <span className={isEmpty(internalValue) ? "input__label-visible" : "input__label-on-border"}>{label}</span>
          <div className="flex-grow-1">
            {map(internalValue, (p) => (
              <div key={p.uuid} style={{ fontSize: "0.875rem" }}>
                {p.firstName} {p.lastName}
              </div>
            ))}
          </div>
          <ActionButton className="flex-grow-0" variant="outline-primary" size="sm" onClick={() => setShowModal(true)}>
            Ändra
          </ActionButton>
        </div>
      </div>
      <SelectRespondantsModal
        show={showModal}
        selectedRespondants={internalValue}
        selectablePersons={selectablePersons}
        onClose={() => setShowModal(false)}
        onSave={updateRespondants}
      />
    </div>
  );
};

export const RespondantsSelect = withErrorFeedback(Component);
