import React from "react";
import Modal from "react-bootstrap/Modal";
import { ActionButton } from "../buttons/ActionButton";

export const ConfirmRemoveModal = (props: {
  show: boolean;
  nameOfTheThingToRemove: string;
  confirmAction: () => void;
  dismissAction: () => void;
}) => {
  const { show, nameOfTheThingToRemove, confirmAction, dismissAction } = props;

  return (
    <>
      <Modal show={show} onHide={dismissAction}>
        <form
          onSubmit={(e) => {
            confirmAction();
            e.preventDefault();
          }}
        >
          <Modal.Body className="p-1">
            <p className="p-2">Är du säker på att du vill ta bort {nameOfTheThingToRemove}?</p>
            <div className="d-flex flex-wrap justify-content-end gap-1 pt-2">
              <ActionButton variant="secondary" size="sm" onClick={dismissAction} type={"button"}>
                Avbryt
              </ActionButton>
              <ActionButton variant="primary" size="sm" type="submit">
                Ta bort
              </ActionButton>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};
