import { isEmpty, reverse, sortBy } from "lodash";
import { Alert } from "react-bootstrap";
import { Loadable } from "../../../App.state";
import { SavedPoll } from "../../../common/backend/model/Poll.model";
import { dateString } from "../../../common/date";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNotifications } from "../../rootpage/Notifications";
import { NestedIconButton } from "../../../common/components/buttons/NestedIconButton";

export interface PollListProps {
  polls: Loadable<Array<SavedPoll>>;
  onOpenPoll: (pollId: string) => void;
}

export const PollList: React.FC<PollListProps> = ({ polls, onOpenPoll }) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const notifications = useNotifications();

  function copyPollUrlToClipBoard(poll: SavedPoll) {
    navigator.clipboard.writeText(
      `${window.location.href.replace(
        /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i,
        ""
      )}/${poll.uuid}`
    );
  }

  function showSuccessfulCopyNotification() {
    notifications.add({
      title: "Länken har kopierats",
      autoRemove: true,
      type: "SUCCESS",
    });
  }

  if (polls.loading || !polls.value) {
    return null;
  }

  return (
    <>
      <div className="d-flex flex-column gap-1">
        <Alert variant="info" show={isEmpty(polls.value)}>
          Hittade inga frågor
        </Alert>
        {reverse(sortBy(polls.value, (p) => p.startDate)).map((p) => (
          <div
            className={`card p-2 shadow-lg ${!isButtonHovered ? "click-target" : ""}`}
            key={p.uuid}
            onClick={() => onOpenPoll(p.uuid)}
          >
            <div className="fs-5 d-flex align-items-center">
              <div className="flex-grow-1">{p?.heading}</div>
              {p.active ? (
                <div
                  className="badge bg-danger"
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    fontSize: "0.75rem",
                    fontWeight: "light",
                  }}
                >
                  <i>Aktiv</i>
                </div>
              ) : null}
              <NestedIconButton
                icon={faLink}
                onMouseEnter={() => setIsButtonHovered(true)}
                onMouseLeave={() => setIsButtonHovered(false)}
                title={"Kopiera länk till frågan"}
                className={"px-2"}
                onClick={() => {
                  copyPollUrlToClipBoard(p);
                  showSuccessfulCopyNotification();
                }}
              />
            </div>
            <div className="fw-light fst-italic">
              Aktiv från {dateString(p?.startDate, "--")} till {dateString(p?.endDate, "--")}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
