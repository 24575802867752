import { isEmpty } from "lodash";
import { CSSProperties, FC, PropsWithChildren } from "react";

export type Size = "sm" | "lg";

export interface ActionButtonProps {
  label?: string;
  variant?: "primary" | "outline-primary" | "secondary" | "outline-secondary";
  type?: "submit" | "button";
  size?: Size;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
}

export const ActionButton: FC<PropsWithChildren<ActionButtonProps>> = (props: PropsWithChildren<ActionButtonProps>) => {
  const {
    variant = "primary",
    style,
    className = "",
    label,
    type,
    disabled,
    children,
    size,
    onClick = () => undefined,
  } = props;

  return (
    <>
      <button
        type={type}
        disabled={disabled}
        style={style}
        className={`btn btn-${variant} rounded-pill py-1 px-3 ${getBsClass(size)} ${className}`.trim()}
        onClick={onClick}
      >
        {isEmpty(children) ? label : children}
      </button>
    </>
  );
};

function getBsClass(size?: Size): string {
  if (!size) {
    return "";
  }
  switch (size) {
    case "sm":
      return "btn-sm";
    case "lg":
      return "btn-lg";
  }
}
