import { useAppState } from "../../App.state";
import { useBackendApi } from "../../common/backend/backendApi";
import React, { useEffect } from "react";
import "./ImageOfTheDay.scss";
import { useNavigate } from "react-router-dom";

export const ImageOfTheDay: React.FC = () => {
  const appState = useAppState().state;
  const backendApi = useBackendApi();
  const navigate = useNavigate();

  useEffect(() => {
    backendApi.gallery.loadImageOfTheDay();
  }, [backendApi.gallery]);

  if (appState.imageOfTheDay?.loading || !appState.imageOfTheDay.value) {
    return null;
  }

  const image = appState.imageOfTheDay.value.image;
  const albumName = appState.imageOfTheDay.value.albumName;
  const albumId = appState.imageOfTheDay.value.albumId;
  const date = new Date(image.metaData.takenOn);
  const yearsAgo = new Date().getFullYear() - date.getFullYear();

  const navigateToImageInAlbum = () => {
    navigate(`/albums/images/${albumId}/${image.uuid}`);
  };

  return (
    <div className="card shadow-lg">
      <div className="card-header">Dagens bild</div>
      <div className="card-title px-3 pt-3">
        {albumName}
        <br />
        <span style={{ fontSize: "0.9rem" }}>
          {`${date.getDate()} ${month(date.getMonth())} ${yearsAgo > 0 ? `för ${yearsAgo} år sedan` : "i år"}`}
        </span>
      </div>
      <div className=" image-of-the-day-container click-target" onClick={() => navigateToImageInAlbum()}>
        <img src={image.fullSizeUrl} className="image-of-the-day shadow-lg" alt={"Navigera till bilden i albumet"} />
      </div>
    </div>
  );
};

function month(month: number) {
  const months = [
    "januari",
    "februari",
    "mars",
    "april",
    "maj",
    "juni",
    "juli",
    "augusti",
    "september",
    "oktober",
    "november",
    "december",
  ];
  return months[month];
}
