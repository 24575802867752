import React from "react";

export interface PersonListFilterProps {
  doFilter: () => void;
  label: string;
}

const PersonListFilter: React.FC<PersonListFilterProps> = ({ doFilter, label }) => {
  return (
    <button
      className="btn btn-link"
      onClick={(e) => {
        e.preventDefault();
        doFilter();
      }}
    >
      {label}
    </button>
  );
};

export default PersonListFilter;
