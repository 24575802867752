import React from "react";
import { Dropdown } from "react-bootstrap";
import { initialsOf, Person } from "../../backend/model/Person.model";
import "./UserSymbols.scss";

interface UserSymbolProps {
  person: Person;
  onEdit: () => void;
}

const COLOR_THEME_KEY = "hem-color-theme"; // used in public/assets/color-modes.js
const COLOR_THEME_ATTR = "data-bs-theme"; // used in public/index.html

function getSystemColorTheme() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
}

export const UserSymbols: React.FC<UserSymbolProps> = ({ person, onEdit }) => {
  const [show, setShow] = React.useState(false);

  const handleToggle = () => setShow(!show);
  const handleClose = () => setShow(false);

  const setColorTheme = (themeName: string) => {
    const preferredTheme = themeName === "auto" ? getSystemColorTheme() : themeName;
    document.documentElement.setAttribute("data-bs-theme", preferredTheme);
    localStorage.setItem(COLOR_THEME_KEY, themeName);
  };

  const isSelected = (theme: string) => {
    const preferredTheme =
      localStorage.getItem(COLOR_THEME_KEY) ?? document.documentElement.getAttribute(COLOR_THEME_ATTR);
    return theme === preferredTheme ? "bi bi-check2" : "bi ms-auto d-none";
  };

  return (
    <div className="user-symbols">
      <Dropdown show={show} onToggle={handleToggle}>
        <div
          onClick={handleToggle}
          className="ms-3 me-2 px-1 border border-1 rounded-3 user-symbol-button dropdown-toggle"
        >
          {initialsOf(person)}
        </div>
        <Dropdown.Menu className="bg-dark text-light dropdown-menu-custom">
          <Dropdown.Item
            onClick={() => {
              onEdit();
              handleClose();
            }}
          >
            Redigera
          </Dropdown.Item>
          <h6 className="dropdown-header">Färgtema</h6>
          <Dropdown.Item onClick={() => setColorTheme("light")}>
            <div data-bs-theme-value="light">
              <i className="bi bi-sun-fill"></i>
              {" Ljust "}
              <i className={isSelected("light")}></i>
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setColorTheme("dark")}>
            <div data-bs-theme-value="dark">
              <i className="bi bi-moon-stars-fill"></i>
              {" Mörkt "}
              <i className={isSelected("dark")}></i>
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setColorTheme("auto")}>
            <div data-bs-theme-value="auto">
              <i className="bi bi-circle-half"></i>
              {" System "}
              <i className={isSelected("auto")}></i>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
