import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App.state";
import { useBackendApi } from "../../../common/backend/backendApi";
import { MediaGrid } from "./MediaGrid";
import { useParams } from "react-router-dom";
import { MediaViewModal } from "./MediaViewModal";
import { Media, MediaType, MediaUpdateDto } from "../../../common/backend/model/Media.model";
import { inOffCanvasHOC } from "../../../common/components/offcanvas/InOffCanvasHOC";
import { MediaEditor } from "./MediaEditor";

const MediaEditFormInOffCanvas = inOffCanvasHOC(MediaEditor, "end", "Ändra info");

export const MediaAdministrationPage: React.FC = () => {
  const controller = useMediaAdministrationPageController();

  if (controller.medias.loading || !controller.album) {
    return null;
  }

  return (
    <main key={controller.albumId} className="container p-2">
      <div className="d-flex align-items-start">
        <div className="ms-0">
          <div className={"album-name"}>{controller.album.name}</div>
          <div className={"album-description"}>{controller.album.description}</div>
          <div className="album-date">{controller.album.albumDate}</div>
        </div>
      </div>

      <MediaGrid
        medias={controller.medias}
        onOpenMedia={(media) => controller.openMedia(media)}
        onOpenMediaEditor={controller.openMediaEditor}
      />

      <MediaEditFormInOffCanvas
        show={controller.showMediaEditor}
        onHide={controller.closeMediaEditor}
        onClose={controller.closeMediaEditor}
        mediaUUID={controller.selectedMedia?.uuid}
        savedMedia={controller.selectedMedia}
        onUpdateMedia={controller.updateMedia}
        onRemoveMedia={controller.deleteMedia}
      />

      <MediaViewModal
        show={controller.showMedia}
        media={controller.selectedMedia}
        onNext={controller.openNextMedia}
        onPrev={controller.openPrevMedia}
        onClose={controller.closeMedia}
      />
    </main>
  );
};

function useMediaAdministrationPageController() {
  const appState = useContext(AppContext).state;
  const backendApi = useBackendApi();

  const [mediasHaveLoadedForThisAlbum, setMediasHaveLoadedForThisAlbum] = useState(false);
  const [urlImageOpened, setUrlImageOpened] = useState(false);

  const [showMediaEditor, setShowMediaEditor] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({} as Media);

  let { albumId, imageId } = useParams();

  useEffect(() => {
    if (!mediasHaveLoadedForThisAlbum) {
      backendApi.gallery.loadAlbums();
      backendApi.gallery.loadImages(albumId as string, true);
      setMediasHaveLoadedForThisAlbum(true);
    }
  }, [backendApi.gallery, albumId, mediasHaveLoadedForThisAlbum]);

  useEffect(() => {
    if (
      mediasHaveLoadedForThisAlbum &&
      !appState.medias.loading &&
      appState.medias.value &&
      !urlImageOpened &&
      imageId
    ) {
      setSelectedMedia(appState.medias.value?.filter((m) => m.uuid === imageId)[0] as Media);
      setShowMedia(true);
      setUrlImageOpened(true);
    }
  }, [appState.medias.value, appState.medias.loading, mediasHaveLoadedForThisAlbum, urlImageOpened, imageId]);

  return {
    albumId: albumId,
    album: appState.albums.value?.find((a) => a.uuid === albumId),
    medias: appState.medias,
    showMedia: showMedia,
    showMediaEditor: showMediaEditor,
    selectedMedia: selectedMedia,

    openMedia: (media: Media) => {
      setSelectedMedia(media);
      setShowMedia(true);
    },
    openNextMedia: () => {
      const nxtIdx = (appState.medias.value?.indexOf(selectedMedia as Media) as number) + 1;
      if (nxtIdx !== appState.medias.value?.length) {
        setShowMedia(false);
        setSelectedMedia((appState.medias.value as Media[])[nxtIdx]);
        setShowMedia(true);
      }
    },
    openPrevMedia: () => {
      const prevIdx = (appState.medias.value?.indexOf(selectedMedia as Media) as number) - 1;
      if (!(prevIdx < 0)) {
        setShowMedia(false);
        setSelectedMedia((appState.medias.value as Media[])[prevIdx]);
        setShowMedia(true);
      }
    },
    closeMedia: () => {
      setShowMedia(false);
    },
    openMediaEditor: (media: Media) => {
      setSelectedMedia(media);
      setShowMediaEditor(true);
    },
    closeMediaEditor: () => {
      setShowMediaEditor(false);
    },
    updateMedia: async (mediaUuid: string, dto: MediaUpdateDto, mediaType: MediaType) => {
      await backendApi.gallery.updateMedia(mediaUuid, dto, mediaType, albumId as string);
      setShowMediaEditor(false);
    },
    deleteMedia: async (mediaUuid: string, mediaType: MediaType) => {
      await backendApi.gallery.deleteMedia(mediaUuid, mediaType, albumId as string);
      setShowMediaEditor(false);
    },
  };
}
