import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App.state";
import { useBackendApi } from "../../common/backend/backendApi";
import { PersonList } from "./PersonList";
import { FloatingActionButton } from "../../common/components/buttons/FloatingActionButton";
import { PersonEditor } from "./PersonEditor";
import { PersonView } from "./PersonView";
import { inOffCanvasHOC } from "../../common/components/offcanvas/InOffCanvasHOC";
import { Person } from "../../common/backend/model/Person.model";
import { useParams } from "react-router-dom";

interface InternalState {
  showCreatePersonForm: boolean;
  showEditPersonForm: boolean;
  showViewPersonForm?: boolean;
  selectedPerson?: Person;
}

const PersonCreateFormInOffCanvas = inOffCanvasHOC(PersonEditor, "end", "Lägg till person");
const PersonEditFormInOffCanvas = inOffCanvasHOC(PersonEditor, "end", "Ändra person");
const PersonViewFormInOffCanvas = inOffCanvasHOC(PersonView, "end", "Visa person");

export const PersonsAdministrationPage: React.FC = () => {
  const controller = usePersonAdministrationPageController();
  const [filterBy, setFilterBy] = useState<string | null>(null);
  const [hasOpenedView, setHasOpenedView] = useState(false);
  let { personId } = useParams();
  useEffect(() => {
    if (personId && controller.persons.value && !hasOpenedView) {
      const person = controller.persons.value.find((p) => p.uuid === personId);
      if (person) {
        setHasOpenedView(true);
        controller.openViewPersonForm(person);
      }
    }
  }, [personId, controller, hasOpenedView]);

  if (controller.persons.loading) {
    return null;
  }

  const getPersons = () => {
    let filteredList;
    if (filterBy && controller.persons.value) {
      filteredList = {
        loading: false,
        value: controller.persons.value.filter((p) => p.group === filterBy),
      };
    }
    return filteredList ?? controller.persons;
  };

  return (
    <main className="container p-2">
      <PersonList
        persons={getPersons()}
        filterBy={setFilterBy}
        onOpenEditPerson={(person) => controller.openEditPersonForm(person)}
        onOpenViewPerson={(person) => controller.openViewPersonForm(person)}
      />

      <PersonCreateFormInOffCanvas
        show={controller.showCreatePersonForm}
        onCreatePerson={controller.createPerson}
        onHide={controller.closeForms}
        onClose={controller.closeForms}
        onValidatePerson={controller.validatePerson}
      />

      <PersonViewFormInOffCanvas
        person={controller.selectedPerson}
        show={Boolean(controller.selectedPerson && controller.showViewPersonForm)}
        onHide={controller.closeViewPersonForm}
        onOpenEditPerson={controller.openEditPersonForm}
      />
      <PersonEditFormInOffCanvas
        person={controller.selectedPerson}
        show={!!controller.selectedPerson && controller.showEditPersonForm}
        onHide={() => controller.closeForms()}
        onUpdatePerson={controller.updatePerson}
        onRemovePerson={controller.deletePerson}
        onClose={() => controller.closeForms()}
        onValidatePerson={controller.validatePerson}
      />
      <FloatingActionButton label="Lägg till" bottom={70} onClick={controller.openCreatePersonForm} />
    </main>
  );
};

function usePersonAdministrationPageController() {
  const appState = useContext(AppContext).state;
  const backendApi = useBackendApi();

  useEffect(() => {
    backendApi.persons.loadPersons();
  }, [backendApi.persons]);

  const [state, setState] = useState<InternalState>({
    showCreatePersonForm: false,
    showEditPersonForm: false,
    showViewPersonForm: true,
  });

  function closeCreatePersonForm() {
    setState((prev) => ({
      ...prev,
      showCreatePersonForm: false,
    }));
  }

  function closeEditPersonForm() {
    setState((prev) => ({
      ...prev,
      showEditPersonForm: false,
    }));
  }

  return {
    persons: appState.persons,
    showCreatePersonForm: state.showCreatePersonForm,
    showEditPersonForm: state.showEditPersonForm,
    showViewPersonForm: state.showViewPersonForm,
    selectedPerson: state.selectedPerson,
    openCreatePersonForm: () => {
      setState((prev) => ({
        ...prev,
        showCreatePersonForm: true,
        showEditPersonForm: false,
      }));
    },
    openViewPersonForm: (person: Person) => {
      setState((prev) => ({
        ...prev,
        showViewPersonForm: true,
        showEditPersonForm: false,
        selectedPerson: person,
      }));
    },

    closeViewPersonForm: () => {
      setState((prev) => ({
        ...prev,
        showViewPersonForm: false,
      }));
    },
    openEditPersonForm: (person: Person | undefined) => {
      if (person) {
        setState((prev) => ({
          ...prev,
          showCreatePersonForm: false,
          showEditPersonForm: true,
          showViewPersonForm: false,
          selectedPerson: person,
        }));
      }
    },
    createPerson: async (person: Person, file: File) => {
      console.log("createPerson: ", person, file);
      await backendApi.persons.createPerson(person, file);
      closeCreatePersonForm();
    },
    updatePerson: async (person: Person, file: File) => {
      console.log("updatePerson: ", person, file);
      await backendApi.persons.updatePerson(person, file);
      closeEditPersonForm();
    },
    deletePerson: async (uuid: string) => {
      await backendApi.persons.deletePerson(uuid);
    },
    validatePerson: async (person: Partial<Person>) => {
      return await backendApi.persons.validatePerson(person);
    },
    closeForms: () =>
      setState((prev) => ({
        ...prev,
        showCreatePersonForm: false,
        showEditPersonForm: false,
        selectedPerson: undefined,
      })),
  };
}
