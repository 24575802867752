import { ChangeEvent, FC, useRef } from "react";
import { LabelledInputBaseProps } from "../labelled-inputs/new-input/LabelledInputBaseProps";

export interface FileUploadProps extends LabelledInputBaseProps {
  buttonLabel?: string;
  mimeTypes?: Array<string>;
  multiple?: boolean;
  onSelectionChange: (files?: Array<File>) => void;
  disabled?: boolean;
}

export const FileSelect: FC<FileUploadProps> = (props) => {
  function fileChange(event: ChangeEvent<HTMLInputElement>) {
    const fileList: FileList = (event.target as HTMLInputElement)?.files ?? new FileList();
    const files: Array<File> = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }
    onSelectionChange(files);
  }

  function openFileSelector(): void {
    fileInput?.current?.click();
  }

  const {
    buttonLabel = "Ladda upp...",
    mimeTypes = ["*/*"],
    multiple = false,
    onSelectionChange = () => undefined,
    disabled = false,
  } = props;

  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={fileInput}
        type="file"
        onChange={fileChange}
        disabled={disabled}
        accept={mimeTypes.reduce((acc, str) => (acc ? `${acc},${str}` : str), "")}
        multiple={multiple}
        className="d-none"
      />
      <button
        className="btn btn-outline-primary col-auto"
        data-testid="btn-select-files"
        onClick={openFileSelector}
        disabled={disabled}
      >
        {buttonLabel}
      </button>
    </>
  );
};
