import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import { FC, useEffect } from "react";
import { useAppState } from "../../App.state";
import { useBackendApi } from "../../common/backend/backendApi";
import { StackLayout } from "../../common/components/layout/StackLayout";
import { useNavigate } from "react-router-dom";

export const NewestLinks: FC = () => {
  const appState = useAppState().state;
  const backendApi = useBackendApi();
  const navigate = useNavigate();
  useEffect(() => {
    backendApi.links.loadNewestLinks();
  }, [backendApi.links]);

  if (appState.newestLinks?.loading || !appState.newestLinks) {
    return null;
  }
  return (
    <div className="card shadow-lg">
      {" "}
      <div className="card-header">Nyaste länkarna</div>{" "}
      <div className="card-body">
        <StackLayout gap={1}>
          {map(appState.newestLinks?.value, (l) => (
            <div key={l.uuid}>
              <a href={l.url} target={l.uuid}>
                {l.title}
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ms-1" />
              </a>
            </div>
          ))}
          <div className="d-flex justify-content-start mt-1 btn btn-link" onClick={() => navigate("links")}>
            Alla länkar...
          </div>
        </StackLayout>
      </div>
    </div>
  );
};
