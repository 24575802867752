import { Loadable } from "../../../App.state";
import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import "./MediaGrid.scss";
import { Media } from "../../../common/backend/model/Media.model";
import { MediaCard } from "./MediaCard";

export interface MediaGridProps {
  medias: Loadable<Array<Media>>;
  onOpenMedia: (media: Media) => void;
  onOpenMediaEditor: (media: Media) => void;
}

export const MediaGrid: React.FC<MediaGridProps> = (props) => {
  const { medias, onOpenMedia, onOpenMediaEditor } = props;

  if (medias.value?.length === 0) {
    return <div className="center-text">Här finns inga bilder ännu</div>;
  }

  return (
    <Container fluid className="p-0">
      <Row xs={2} sm={3} md={4} lg={7} className="g-3">
        {medias.value?.map((m) => (
          <Col key={m.uuid}>
            <MediaCard media={m} onOpenMedia={(media) => onOpenMedia(media)} onOpenEditor={onOpenMediaEditor} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
