import React from "react";
import { actualStockValue, Stock } from "../../backend/model/Stock.model";
import { STOCK_URL } from "../../utils/constants";
import "./StockDisplay.scss";

interface StockDisplayProps {
  stockData?: Stock;
}

const determineColorClass = (status: string) => {
  switch (status) {
    case "POSITIVE":
      return "positive";
    case "UNCHANGED":
      return "unchanged";
    default:
      return "negative";
  }
};

export const StockDisplay: React.FC<StockDisplayProps> = ({ stockData }) => {
  if (!stockData) {
    return null;
  }

  return (
    <div className={"stock-indicator"}>
      <a href={STOCK_URL} target="_blank" rel="noreferrer" className={`hyperlink`}>
        <span className={`stock-prefix`}>{stockData.name}&nbsp;</span>
        <span className={`${determineColorClass(stockData.status)}`}>{actualStockValue(stockData)}</span>
      </a>
    </div>
  );
};
