import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dictionary, filter, flatMap, groupBy, keyBy, keys, map, values } from "lodash";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Person } from "../../backend/model/Person.model";
import { Respondant } from "../../backend/model/Poll.model";
import { translateGroup } from "../../utils/translateGroup";
import { ActionButton } from "../buttons/ActionButton";

interface SelectRespondantsModalProps {
  show: boolean;
  selectedRespondants?: Array<Respondant>;
  selectablePersons?: Array<Person>;
  onClose: () => void;
  onSave: (value: Array<Respondant>) => void;
}

export const SelectRespondantsModal: React.FC<SelectRespondantsModalProps> = (props: SelectRespondantsModalProps) => {
  function updateSelection(group: string) {
    return (selection: Array<Respondant>) =>
      setValue((prev) => {
        return { ...prev, [group]: selection };
      });
  }

  const { show, selectedRespondants = [], selectablePersons = [], onClose, onSave } = props;
  const [value, setValue] = useState<Dictionary<Array<Respondant>>>(groupBy(selectedRespondants, (r) => r.group));

  const personsByGroup = groupBy(selectablePersons, (p) => p.group);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setValue({});
          onClose();
        }}
      >
        <Modal.Body className="p-2">
          <h6>Ange tillfrågade</h6>
          {keys(personsByGroup).map((group) => (
            <DepartmentPersonsSelect
              key={group}
              group={group}
              selectablePersons={personsByGroup[group]}
              selectedRespondants={filter(selectedRespondants, (r) => r.group === group)}
              onChange={updateSelection(group)}
            />
          ))}

          <div className="d-flex flex-wrap justify-content-end gap-1 pt-2">
            <ActionButton
              variant="secondary"
              size="sm"
              className="py-0"
              onClick={() => {
                setValue({});
                onClose();
              }}
            >
              Avbryt
            </ActionButton>
            <ActionButton
              variant="primary"
              size="sm"
              className="py-0"
              onClick={() => {
                setValue({});
                onSave(flatMap(values(value)));
              }}
            >
              Spara
            </ActionButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DepartmentPersonsSelect = (props: {
  group: string;
  selectablePersons: Array<Person>;
  selectedRespondants: Array<Respondant>;
  onChange: (selection: Array<Respondant>) => void;
}) => {
  function togglePerson(p: Respondant): void {
    setInternalState((prevValue) => {
      const newState = { ...prevValue };
      if (newState[p.uuid]) {
        delete newState[p.uuid];
      } else {
        newState[p.uuid] = p;
      }
      onChange(values(newState));
      return newState;
    });
  }

  function selectAll() {
    setInternalState(() => {
      const newState = keyBy(selectablePersons, (p) => p.uuid);
      onChange(values(newState));
      return newState;
    });
  }

  function deselectAll() {
    setInternalState(() => {
      const newState = {};
      onChange(values(newState));
      return newState;
    });
  }

  const { group, selectablePersons = [], selectedRespondants = [], onChange } = props;

  const [internalState, setInternalState] = useState<Dictionary<Respondant>>(keyBy(selectedRespondants, (p) => p.uuid));

  return (
    <>
      <div className="d-flex flex-wrap gap-1 mt-2">
        <div className="flex-grow-1 fs-5">{translateGroup(group)}</div>
        <button onClick={() => selectAll()} className={`btn btn-link shadow-none p-0 flex-grow-0`}>
          Alla
        </button>
        <button onClick={() => deselectAll()} className={`btn btn-link shadow-none p-0 flex-grow-0`}>
          Ingen
        </button>
      </div>
      <div className="d-flex flex-wrap gap-1">
        {map(selectablePersons, (p) => (
          <Button
            key={p.uuid}
            onClick={() => togglePerson(p)}
            variant={internalState[p.uuid] ? "secondary" : "outline-secondary"}
            className={`btn btn-sm py-0`}
          >
            {p.firstName} {p.lastName}
            {internalState[p.uuid] ? <FontAwesomeIcon className="ms-1" icon={faCheck} /> : null}
          </Button>
        ))}
      </div>
    </>
  );
};
