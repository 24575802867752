import { isEmpty, sortBy } from "lodash";
import { Alert } from "react-bootstrap";
import { Loadable } from "../../../App.state";
import { SavedLink } from "../../../common/backend/model/Link.model";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNotifications } from "../../rootpage/Notifications";
import { NestedIconButton } from "../../../common/components/buttons/NestedIconButton";

export interface LinkListProps {
  links: Loadable<Array<SavedLink>>;
  onOpenLink: (linkId: string) => void;
}

export const LinkList: React.FC<LinkListProps> = ({ links, onOpenLink }) => {
  const notifications = useNotifications();
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  function copyLinkUrlToClipBoard(link: SavedLink) {
    navigator.clipboard.writeText(
      `${window.location.href
        .replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i, "")
        .replace(/linksadmin/i, "links")}/${link.uuid}`
    );
  }

  function showSuccessfulCopyNotification() {
    notifications.add({
      title: "Länken har kopierats",
      autoRemove: true,
      type: "SUCCESS",
    });
  }

  if (links.loading || !links.value) {
    return null;
  }

  return (
    <>
      <div className="d-flex flex-column gap-1">
        {isEmpty(links.value) ? <Alert variant="info">Hittade inga länkar</Alert> : null}
        {sortBy(links.value, (link) => link.title).map((link) => (
          <div
            className={`card shadow-lg p-2 ${!isButtonHovered ? "click-target" : ""}`}
            key={link.uuid}
            onClick={() => onOpenLink(link.uuid)}
          >
            <div className="fs-5 d-flex">
              <div className="flex-grow-1">{link?.title}</div>

              <NestedIconButton
                icon={faLink}
                onClick={() => {
                  copyLinkUrlToClipBoard(link);
                  showSuccessfulCopyNotification();
                }}
                className={"px-2 align-self-center"}
                title={"Kopiera delbar länk till länken"}
                onMouseEnter={() => setIsButtonHovered(true)}
                onMouseLeave={() => setIsButtonHovered(false)}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
