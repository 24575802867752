import React, { useState } from "react";
import { LabelledTextArea } from "../../../common/components/labelled-inputs/LabelledTextArea";
import { LabelledTextInput } from "../../../common/components/labelled-inputs/LabelledTextInput";
import { ActionButton } from "../../../common/components/buttons/ActionButton";
import { ConfirmRemoveModal } from "../../../common/components/modal/ConfirmRemoveModal";
import { Media, MediaType, MediaUpdateDto } from "../../../common/backend/model/Media.model";
import { Metadata } from "../../../common/backend/model/Metadata.model";
import { LabelledDateInput } from "../../../common/components/labelled-inputs/LabelledDateInput";

export interface MediaEditorProps {
  mediaUUID: string;
  savedMedia: Media;
  onUpdateMedia: (uuid: string, toSave: MediaUpdateDto, mediaType: MediaType) => void;
  onRemoveMedia: (uuid: string, mediaType: MediaType) => void;
  onClose: () => void;
}

export const MediaEditor: React.FC<MediaEditorProps> = (props) => {
  const { mediaUUID, savedMedia, onUpdateMedia, onRemoveMedia, onClose } = props;
  const [editedMetadata, setEditedMetadata] = useState<Partial<Metadata>>(savedMedia.metaData);
  const [modified, setModified] = useState(false);
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);

  function updateEditedMedia(fields: Partial<Metadata>) {
    setEditedMetadata((prevValue) => {
      const newValue = {
        ...prevValue,
        ...fields,
      };
      setModified(true);
      return newValue;
    });
  }

  return (
    <>
      <div>
        <LabelledTextInput
          focus={true}
          value={editedMetadata?.photographer}
          label="Fotograf"
          className="mb-2"
          onChange={(photographer) => updateEditedMedia({ photographer })}
        />

        <LabelledTextArea
          focus={true}
          value={editedMetadata?.description}
          label="Beskrivning"
          className="mb-2"
          onChange={(description) => updateEditedMedia({ description })}
        />

        <LabelledDateInput
          includeTime={true}
          value={editedMetadata?.takenOn}
          label="Tidpunkt"
          className="mb-2"
          onChange={(takenOn) => updateEditedMedia({ takenOn: `${takenOn}` })}
        />

        <div className="mt-3 d-flex flex-wrap gap-1 justify-content-end">
          <ActionButton
            label="Ta bort"
            variant="secondary"
            className="btn-danger"
            onClick={() => setShowConfirmRemoveModal(true)}
          />
          <ActionButton label="Avbryt" variant="secondary" onClick={() => onClose()} />
          <ActionButton
            label="Spara"
            disabled={!modified}
            onClick={async () => {
              onUpdateMedia(mediaUUID, { metadata: editedMetadata as Metadata }, savedMedia.mediaType);
            }}
          />
        </div>

        <ConfirmRemoveModal
          nameOfTheThingToRemove={"objektet"}
          show={showConfirmRemoveModal}
          confirmAction={() => {
            onRemoveMedia(mediaUUID, savedMedia.mediaType);
            setShowConfirmRemoveModal(false);
          }}
          dismissAction={() => setShowConfirmRemoveModal(false)}
        />
      </div>
    </>
  );
};
