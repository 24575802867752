import { Album } from "../../../common/backend/model/Album.model";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import "./AlbumCard.scss";
import { NestedIconButton } from "../../../common/components/buttons/NestedIconButton";

export interface AlbumCardProps {
  album: Album;
  onOpenEditAlbum: (albumUuid: string) => void;
}

export const AlbumCard: React.FC<AlbumCardProps> = (props) => {
  const { album, onOpenEditAlbum } = props;
  const [isEditButtonHovered, setIsEditButtonHovered] = useState(false);

  const navigate = useNavigate();

  const navigateIntoAlbum = () => {
    navigate(`/albums/images/${album.uuid}`);
  };

  return (
    <Card
      className={`square-card shadow-lg p-2 h-100 ${!isEditButtonHovered ? "click-target" : ""}`}
      onClick={navigateIntoAlbum}
      title={album.description}
    >
      <div className="square-card-img-wrapper shadow-lg">
        <Card.Img
          variant="top"
          src={album.thumbnailUrl || "/assets/default-album-blue.png"}
          className="square-card-img"
        />
      </div>
      <div className={"d-flex justify-content-between align-items-start"}>
        <Card.Body className={"p-0"}>
          <Card.Text style={{ fontSize: "0.8rem", margin: "1px" }}>{album.name}</Card.Text>
          <Card.Text style={{ fontSize: "0.6rem", margin: "1px" }}>
            {album.albumDate} - {album.numberOfImages + album.numberOfVideos} {"objekt"}
          </Card.Text>
        </Card.Body>

        <NestedIconButton
          icon={faGear}
          className={"pt-2"}
          appearOnlyOnCardHover
          onClick={() => onOpenEditAlbum(album.uuid)}
          title={"Ändra album"}
          onMouseEnter={() => setIsEditButtonHovered(true)}
          onMouseLeave={() => setIsEditButtonHovered(false)}
        />
      </div>
    </Card>
  );
};
