export enum Category {
  WEEKLY_LETTER = "WEEKLY_LETTER",
  POLICY_DOCUMENT = "POLICY_DOCUMENT",
  CONTACTOR = "CONTACTOR",
  PRESENTATION = "PRESENTATION",
  CAG = "CAG",
}

export interface BasicLinkData {
  url?: string;
  title: string;
  description?: string;
  category: Category;
  type: LinkType;
}

export type LinkType = "URL_LINK" | "FILE_LINK";

export interface SavedLink extends BasicLinkData {
  uuid: string;
}
