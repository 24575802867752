import { CalendarEvent } from "./CalenderEvent.model";
import { LabelledDateInput } from "../../common/components/labelled-inputs/LabelledDateInput";
import { LabelledTextArea } from "../../common/components/labelled-inputs/LabelledTextArea";
import { ActionButton } from "../../common/components/buttons/ActionButton";
import { LabelledTextInput } from "../../common/components/labelled-inputs/LabelledTextInput";
import { useState } from "react";
import { DateInputUtil } from "../../common/date";
import { isEmpty } from "lodash";

export interface CalendarEventEditorProps {
  calendarEvent: CalendarEvent | undefined;
  onSave: (newOrUpdated: CalendarEvent) => void;
  onCancel: () => void;
  onDelete: () => void;
}

export function CalendarEventEditor(props: CalendarEventEditorProps) {
  const [calendarEvent, setCalendarEvent] = useState(props.calendarEvent);
  const { onSave, onCancel, onDelete } = props;

  //TODO: What events should be deletable?
  const isEventDeletable = () => {
    return !isEmpty(calendarEvent?.uuid);
  };

  return (
    <>
      {calendarEvent && (
        <div>
          <LabelledTextInput
            focus={true}
            value={calendarEvent.title}
            label="Rubrik"
            className="mb-2"
            onChange={(value) => setCalendarEvent({ ...calendarEvent, title: value })}
          />

          <LabelledTextArea
            label="Frivillig extratext"
            value={calendarEvent.description}
            onChange={(value) => setCalendarEvent({ ...calendarEvent, description: value })}
          />

          <LabelledDateInput
            className="mb-2"
            label="När"
            includeTime={true}
            value={DateInputUtil.formatDateTime(calendarEvent.eventDate)}
            onChange={(dateStr) =>
              setCalendarEvent({
                ...calendarEvent,
                eventDate: dateStr!,
              })
            }
          />

          <LabelledTextInput
            focus={false}
            value={calendarEvent.url}
            label="Länk"
            className="mb-2"
            onChange={(value) => setCalendarEvent({ ...calendarEvent, url: value })}
          />

          {!isEmpty(calendarEvent.url) ? <a href={calendarEvent.url}>{calendarEvent.title}</a> : null}

          <div className="mt-3 d-flex flex-wrap gap-1 justify-content-end">
            {isEventDeletable() ? (
              <ActionButton label="Ta bort" variant="secondary" className="btn-danger" onClick={() => onDelete()} />
            ) : null}

            <ActionButton label="Avbryt" variant="secondary" onClick={() => onCancel()} />

            <ActionButton label="Spara" disabled={false} onClick={() => onSave(calendarEvent)} />
          </div>
        </div>
      )}
    </> // end-of-component
  );
}
